// @flow
import React, { useContext, useEffect, useState } from 'react';
import {
    LandingContext,
    PacingAnalysisContext,
    TargetAllocationContext,
} from 'context';
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';

import { numberFormat, colorCombos, getDisplayDates } from 'utils';

type Props = {
    viewYear: Boolean,
    viewHistorical: Boolean,
    viewHighLow: Boolean,
    useCovidScenario: Booloean,
};

const NetCashFlowGraph = ({
    viewYear,
    viewHistorical,
    viewHighLow,
    useCovidScenario,
}: Props) => {
    const {
        graphData,
        graphData: {
            assetclass_yr: assetClassYear,
            assetclass_qtr: assetClassQtr,
            fund_yr_crisis: fundYearCrisis,
            fund_qtr_crisis: fundQtrCrisis,
            fund_yr_hl: fundYearHighLow,
            fund_qtr_hl: fundQtrHighLow,
        },
        historicalData,
        pacingParameters: { dateType, fiscalMonth },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);
    const {
        targetAllocation: { currency },
    } = useContext(TargetAllocationContext);

    const [data, setDataPoints] = useState({});

    const setData = () => {
        let assetClassData = {};
        let covidData = {};
        let highLowData = {};

        if (viewYear) {
            assetClassData = assetClassYear;
            highLowData = fundYearHighLow;
            covidData = fundYearCrisis;
        } else {
            assetClassData = assetClassQtr;
            highLowData = fundQtrHighLow;
            covidData = fundQtrCrisis;
        }

        if (!assetClassData || !covidData) {
            return;
        }

        let years = [];
        const contribHolder = {};
        const distribHolder = {};
        Object.keys(assetClassData).forEach((key) => {
            const values = assetClassData[key].slice(2);
            if (assetClassData[key][1] === 'Contributions') {
                values.forEach((value, index) => {
                    if (contribHolder[index]) {
                        contribHolder[index] += value * -1;
                    } else {
                        contribHolder[index] = value * -1;
                    }
                });
            } else if (assetClassData[key][1] === 'Distributions') {
                values.forEach((value, index) => {
                    if (distribHolder[index]) {
                        distribHolder[index] += value;
                    } else {
                        distribHolder[index] = value;
                    }
                });
            }
        });

        let contributions = [];
        let distributions = [];
        let netCashFlow = [];
        let historicalContrib = [];
        let historicalDistrib = [];
        const emptyFutureContrib = [];
        const emptyFutureDistrib = [];
        const emptyFutureNetCashFlows = [];
        Object.keys(contribHolder).forEach((key) => {
            contributions.push(numberFormat(contribHolder[key], 1));
            distributions.push(numberFormat(distribHolder[key], 1));
            netCashFlow.push(
                numberFormat(distribHolder[key] + contribHolder[key], 1)
            );

            emptyFutureContrib.push();
            emptyFutureDistrib.push();
            emptyFutureNetCashFlows.push();
        });

        const covidContribHolder = {};
        const covidDistribHolder = {};
        Object.keys(covidData).forEach((key) => {
            const cValues = covidData[key].slice(4);
            if (covidData[key][3] === 'Contributions.Crisis') {
                cValues.forEach((value, index) => {
                    if (covidContribHolder[index]) {
                        covidContribHolder[index] += value * -1;
                    } else {
                        covidContribHolder[index] = value * -1;
                    }
                });
            } else if (covidData[key][3] === 'Distributions.Crisis') {
                cValues.forEach((value, index) => {
                    if (covidDistribHolder[index]) {
                        covidDistribHolder[index] += value;
                    } else {
                        covidDistribHolder[index] = value;
                    }
                });
            }
        });

        const covidContributions = [];
        const covidDistributions = [];
        let covidNetCashFlow = [];
        Object.keys(covidContribHolder).forEach((key) => {
            covidContributions.push(numberFormat(covidContribHolder[key], 1));
            covidDistributions.push(numberFormat(covidDistribHolder[key], 1));
            covidNetCashFlow.push(
                numberFormat(
                    covidDistribHolder[key] + covidContribHolder[key],
                    1
                )
            );
        });

        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                    }
                }
            }
        } else {
            ({ years } = getDisplayDates(
                graphData.qtrdates,
                null,
                useForwardNav
            ));
        }

        if (historicalData && viewHistorical) {
            const {
                historical_annual: {
                    Year,
                    contrib: annualContribution,
                    distrib: annualDistribution,
                    ncf: annualNcf,
                },
                historical_qtr: {
                    date,
                    contrib: qtrContribution,
                    distrib: qtrDistribution,
                    ncf: qtrNcf,
                },
            } = historicalData;

            // Format all values to 2 decimals
            const annualContrib = annualContribution.map((value) => {
                return numberFormat(value * -1, 1);
            });
            const qtrContrib = qtrContribution.map((value) => {
                return numberFormat(value * -1, 1);
            });
            const annualDistrib = annualDistribution.map((value) => {
                return numberFormat(value, 1);
            });
            const qtrDistrib = qtrDistribution.map((value) => {
                return numberFormat(value, 1);
            });
            const annualNetCashFlow = annualNcf.map((value) => {
                return numberFormat(value, 1);
            });
            const qtrNetCashFlow = qtrNcf.map((value) => {
                return numberFormat(value, 1);
            });

            const latestYear = Year[Year.length - 1].toString();
            const { years: histDates } = getDisplayDates(date);

            const newContrib = viewYear ? [...annualContrib] : [...qtrContrib];
            const newDistrib = viewYear ? [...annualDistrib] : [...qtrDistrib];
            const newNetCashFlow = viewYear
                ? [...annualNetCashFlow]
                : [...qtrNetCashFlow];
            const emptyCovidNetCashFlow = new Array(newContrib.length);
            const emptyContributions = new Array(newContrib.length);
            const emptyDistributions = new Array(newContrib.length);
            const newYears = viewYear ? [...Year] : [...histDates];
            if (viewYear && years[0] === latestYear) {
                // If data overlaps, merge values (commitment)
                contributions[0] += annualContrib[annualContrib.length - 1];
                distributions[0] += annualDistrib[annualDistrib.length - 1];
                netCashFlow[0] += newNetCashFlow[newNetCashFlow.length - 1];
            } else if (
                !viewYear &&
                years[0] === 'Current'
            ) {
                // If data overlaps, merge values (commitment)
                contributions[0] += qtrContrib[qtrContrib.length - 1];
                distributions[0] += qtrDistrib[qtrDistrib.length - 1];
                netCashFlow[0] += newNetCashFlow[newNetCashFlow.length - 1];
            }

            newContrib.pop();
            newDistrib.pop();
            newNetCashFlow.pop();

            emptyCovidNetCashFlow.pop();
            emptyContributions.pop();
            emptyDistributions.pop();

            newYears.pop();

            contributions = emptyContributions.concat(contributions);
            distributions = emptyDistributions.concat(distributions);
            netCashFlow = newNetCashFlow.concat(netCashFlow);
            covidNetCashFlow = emptyCovidNetCashFlow.concat(covidNetCashFlow);
            years = newYears.concat(years);

            historicalContrib = newContrib.concat(emptyFutureContrib);
            historicalDistrib = newDistrib.concat(emptyFutureDistrib);
        }

        const netCashFlowUncertainty = [];
        if (viewHighLow) {
            if (historicalData && viewHistorical) {
                const {
                    historical_annual: { ncf: yearData },
                    historical_qtr: { ncf: qtrData },
                } = historicalData;

                const emptyLength = viewYear ? yearData : qtrData;
                for (let i = 0; i < emptyLength.length - 1; i++) {
                    netCashFlowUncertainty.push({
                        min: null,
                        max: null,
                    });
                }
            }

            const length = Object.keys(highLowData).length;
            const min = highLowData[length - 1].slice(5);
            const max = highLowData[length - 2].slice(5);

            min.forEach((val, index) => {
                netCashFlowUncertainty.push({
                    min: numberFormat(val),
                    max: numberFormat(max[index]),
                });
            });
        }

        setDataPoints({
            contributions,
            distributions,
            covidContributions,
            covidDistributions,
            netCashFlow,
            covidNetCashFlow,
            netCashFlowUncertainty,
            years,
            historicalContrib,
            historicalDistrib,
        });
    };

    useEffect(() => {
        if (
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_yr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_qtr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'fund_yr_crisis') &&
            Object.prototype.hasOwnProperty.call(graphData, 'fund_qtr_crisis')
        ) {
            setData();
        }
    }, [
        viewYear,
        graphData.assetclass_qtr,
        graphData.assetclass_yr,
        graphData.fund_qtr_crisis,
        graphData.fund_yr_crisis,
        useCovidScenario,
        viewHistorical,
        historicalData,
        viewHighLow,
    ]);

    const parsedFiscalMonth = fiscalMonth === '' ? 'September' : fiscalMonth;

    const shortMonths = { September: 'Sept.', June: 'Jun.', March: 'Mar.' };

    const addFY = (year) => {
        const month = shortMonths[parsedFiscalMonth];
        return `FY ${month} ${year}`;
    };

    const dataYears = data.years?.map(addFY);
    const yearLabels = dateType === 'fiscal' ? dataYears : data.years;

    const label = viewYear
        ? { step: 1, rotation: 'auto', position: 'start' }
        : { step: 1, rotation: 'auto', position: 'start' };

    const title =
        dateType === 'fiscal' && viewYear
            ? { text: 'Fiscal Years' }
            : { text: 'Years' };

    return (
        <div className='panel'>
            <Chart pannable={false} zoomable={false}>
                <ChartLegend
                    visible
                    position='bottom'
                    orientation='horizontal'
                />
                <ChartAxisDefaults majorGridLines={false} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        categories={yearLabels}
                        labels={label}
                        title={title}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: `Value in ${currency}` }} />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        name='Contributions'
                        type='column'
                        color={colorCombos[1].primary}
                        data={data.contributions}
                        stack
                    />
                    <ChartSeriesItem
                        name='Historical Contributions'
                        type='column'
                        color={colorCombos[1].primary}
                        data={data.historicalContrib}
                        opacity={0.5}
                        stack
                    />
                    <ChartSeriesItem
                        name='Distributions'
                        type='column'
                        color={colorCombos[0].primary}
                        data={data.distributions}
                        stack
                    />
                    <ChartSeriesItem
                        name='Historical Distributions'
                        type='column'
                        color={colorCombos[0].primary}
                        data={data.historicalDistrib}
                        opacity={0.5}
                        stack
                    />
                    <ChartSeriesItem
                        name='Net Cash Flows'
                        type='line'
                        color={colorCombos[2].primary}
                        data={data.netCashFlow}
                    />
                    <ChartSeriesItem
                        name='Downturn Scenario'
                        type='line'
                        color={colorCombos[3].primary}
                        data={useCovidScenario ? data.covidNetCashFlow : null}
                        visibleInLegend={useCovidScenario}
                        stack
                    />
                    <ChartSeriesItem
                        id='Net Cash Flows Uncertainty'
                        name='Net Cash Flows Uncertainty'
                        type='rangeArea'
                        data={data.netCashFlowUncertainty}
                        color={colorCombos[2].primary}
                        fromField='min'
                        toField='max'
                        stack
                    />
                </ChartSeries>
                <ChartTooltip />
            </Chart>
        </div>
    );
};

export default NetCashFlowGraph;
