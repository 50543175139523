import React, { useContext, useEffect, useRef } from 'react';

import { checkConversusAccess } from 'utils';
import { AuthContext, TargetAllocationContext, LandingContext, StaticsContext, PacingAnalysisContext, UnderwritingContext } from 'context';

import useTargetAllocationGrid from './hooks';
import columnDefinition from './columnDefinition';

import { UnderwritingFrameworkComponents } from '../../components';
import { Grid, TableVariant } from '@ssgglobal/techintnue';

const TargetAllocationGrid = ({ scenario, onSelected }) => {
    const gridApi = useRef();
    const { reportDate } = useContext(LandingContext);
    const { user, userIsAdmin } = useContext(AuthContext);
    const { targetAllocationStrategies } = useContext(StaticsContext);
    const {
        strategyAllocation,
    } = useContext(TargetAllocationContext);
    const { checkForRenamedStrategy } = useContext(PacingAnalysisContext);

    const { data, updateValue, resetRow, emptyUpdatedAfterSort } = useTargetAllocationGrid({ api: gridApi?.current?.api });
    const conversusAccess = checkConversusAccess(user);
    const frameworkComponents = UnderwritingFrameworkComponents();

    const getStrategyDisplayName = (value) => {
        const foundStrat = checkForRenamedStrategy(value);
        if (foundStrat) return foundStrat;
        return value;
    };

    const handlePostSortRows = (params) => {
        const { nodes: rowNodes } = params;
        const sorted = rowNodes.filter((node) => node.data.lastUpdated).sort((a, b) => {
            return a.data.lastUpdated.index - b.data.lastUpdated.index
        });

        // here we put Ireland rows on top while preserving the sort order
        sorted.forEach((node) => {
            const { lastUpdated } = node.data;
            if (lastUpdated !== undefined) {
                // should move the node to rowIndex
                const rowIndex = lastUpdated.index;
                const oldIndex = rowNodes.findIndex((oldNode) => (
                    oldNode.data.strategy === node.data.strategy
                    && oldNode.data.scenario === node.data.scenario
                ));
                if (oldIndex || oldIndex === 0) {
                    // if found index
                    const foundNode = rowNodes.splice(oldIndex, 1)[0] // removes the current node from list
                    rowNodes.splice(
                        rowIndex, // index where to add
                        0, // dont remove nothing
                        foundNode, // it add the node again in the correct position
                    );
                }
            }
        })

    }

    const calculateSizes = () => {
        if (gridApi && gridApi.current && gridApi.current.api) {
            const { gridBodyCtrl: panel } = gridApi.current.api;
            if (panel && panel) {
                const {
                    eBodyViewport: {
                        clientWidth: availableWidth,
                    },
                    columnModel: {
                        displayedColumns,
                        getWidthOfColsInList,
                    }
                } = panel;
                const usedWidth = getWidthOfColsInList(displayedColumns);
                if (usedWidth < availableWidth) {
                    gridApi.current.api.sizeColumnsToFit();
                }
            }
        }
    }

    const calculateFilters = () => {
        if (gridApi && gridApi.current && gridApi.current.api) {
            const scenarioFilter = gridApi.current.api.getFilterInstance('scenario');
            if (scenarioFilter) {
                if (scenario !== 'All') {
                    scenarioFilter.setModel({
                        type: 'set',
                        values: [scenario],
                    });
                } else {
                    scenarioFilter.setModel({});
                }
            }
            gridApi.current.api.onFilterChanged();
        }
    }

    useEffect(() => {
        // re apply on data change (in cases there are no rows, the filters don't apply for some reason)
        calculateFilters();
    }, [data, scenario]);

    const handleOnSelectedRow = (e) => {
        if (!e.data) return;
        onSelected(e.data);
    };

    const columnDefs = columnDefinition(
        updateValue,
        resetRow,
        conversusAccess,
        reportDate,
        targetAllocationStrategies,
        userIsAdmin,
        getStrategyDisplayName,
    );
    return (
        <Grid
            sx={{
                height: '500px',
                '& .ag-body-viewport': {
                    '& > *': {
                        minHeight: '100% !important'
                    },
                    border: 'var(--ag-borders) var(--ag-border-color)',
                }
            }}
        >
            <TableVariant
                gridStyle={{ height: '500px' }}
                ref={gridApi}
                tableType="aggrid"
                minHeight="500px"
                agTheme="ag-theme-balham"
                className="ag-grid-custom"
                agGridOptions={{
                    height: '500px',
                    rowSelection: 'single',
                    defaultColDef: { resizable: true, filter: true, sortable: true, enablePivot: true, },
                    columnDefs: columnDefs,
                    rowData: [...data] || [],
                    components: frameworkComponents,
                    onRowSelected: handleOnSelectedRow,
                    onSortChanged: (params) => {
                        if (params.source === "uiColumnSorted") {
                            emptyUpdatedAfterSort();
                        }
                    },
                    postSortRows: handlePostSortRows,
                    singleClickEdit: true,
                    stopEditingWhenCellsLoseFocus: true,
                    suppressScrollOnNewData: true,
                    rowSelection: 'single',
                    onGridReady: (event) => {
                        calculateFilters();
                        calculateSizes();
                    },
                }}
            />
        </Grid>
    );
};

export default TargetAllocationGrid;
