// @flow
import React, { useContext, useEffect, useState } from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import {
    colorCombos,
    numberFormat,
    getDisplayDates,
    getQuarterFromDate,
} from 'utils';
import { LandingContext, PacingAnalysisContext, TargetAllocationContext } from 'context';

type Props = {
    viewYear: Boolean,
    viewHistorical: Boolean,
    viewHighLow: Boolean,
};

const CommitmentNavGraph = ({
    viewYear,
    viewHistorical,
    viewHighLow,
}: Props) => {
    const {
        graphData,
        historicalData,
        pacingParameters: { dateType, privateMarketExposure },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);
    const { targetAllocation: { currency }, } = useContext(TargetAllocationContext); 
    const [data, setDataPoints] = useState({});
    const [prvMrkPercentOfAuM, setPrvMrkPercentOfAuM] = useState();

    const setData = () => {
        let assetClassData = {};
        let highLowData = {};
        if (viewYear) {
            assetClassData = graphData.assetclass_yr;
            highLowData = graphData.fund_yr_hl;
        } else {
            assetClassData = graphData.assetclass_qtr;
            highLowData = graphData.fund_qtr_hl;
        }

        if (!assetClassData) {
            return;
        }

        const portAum = assetClassData['0'].slice(2);

        let years = [];
        const navHolder = {};
        const commitmentHolder = {};

        Object.keys(assetClassData).forEach((key) => {
            const values = assetClassData[key].slice(2);
            if (assetClassData[key][1] === 'NAV') {
                values.forEach((value, index) => {
                    if (navHolder[index]) {
                        navHolder[index] += value;
                    } else {
                        navHolder[index] = value;
                    }
                });
            } else if (assetClassData[key][1] === 'Commitments') {
                values.forEach((value, index) => {
                    if (commitmentHolder[index]) {
                        commitmentHolder[index] += value;
                    } else {
                        commitmentHolder[index] = value;
                    }
                });
            }
        });

        let nav = [];
        let commitment = [];
        let navPercent = [];
        let historicalCommitment = [];
        let historicalNav = [];
        const emptyFutureCommitment = [];
        const emptyFutureNav = [];
        Object.keys(navHolder).forEach((key) => {
            nav.push(numberFormat(navHolder[key], 0));
            commitment.push(numberFormat(commitmentHolder[key]));
            navPercent.push(
                numberFormat((navHolder[key] / portAum[key]) * 100, 1)
            );

            emptyFutureCommitment.push();
            emptyFutureNav.push();
        });

        const navPercentUncertainty = [];
        if (viewHighLow) {
            if (historicalData && viewHistorical) {
                const {
                    historical_annual: { ncf: yearData },
                    historical_qtr: { ncf: qtrData },
                } = historicalData;

                const emptyLength = viewYear ? yearData : qtrData;
                for (let i = 0; i < emptyLength.length - 1; i++) {
                    navPercentUncertainty.push({
                        min: null,
                        max: null,
                    });
                }
            }

            const length = Object.keys(highLowData).length;
            const min = highLowData[length - 3].slice(5);
            const max = highLowData[length - 4].slice(5);

            min.forEach((val, index) => {
                navPercentUncertainty.push({
                    min: numberFormat(val * 100),
                    max: numberFormat(max[index] * 100),
                });
            });
        }

        let newPrvMrkPercentOfAuM = [];
        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
                for (let j = 0; j < graphData.year_labels.length; j++) {
                    newPrvMrkPercentOfAuM.push(privateMarketExposure);
                }
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                        newPrvMrkPercentOfAuM.push(privateMarketExposure);
                    }
                }
            }
        } else {
            ({ years, percent: newPrvMrkPercentOfAuM } = getDisplayDates(
                graphData.qtrdates,
                privateMarketExposure,
                useForwardNav
            ));
        }

        if (historicalData && viewHistorical) {
            const {
                historical_annual: {
                    Year,
                    commit: annualCommit,
                    nav: annualNav,
                },
                historical_qtr: { date, commit: qtrCommit, nav: qtrNav },
            } = historicalData;
            const latestYear = Year[Year.length - 1].toString();
            const { years: histDates } = getDisplayDates(date);

            const newCommit = viewYear ? [...annualCommit] : [...qtrCommit];
            const newNav = viewYear ? [...annualNav] : [...qtrNav];
            const newYears = viewYear ? [...Year] : [...histDates];
            const newNavPercent = new Array(newYears.length);
            const emptyCommitment = new Array(newYears.length);
            const emptyNav = new Array(newYears.length);

            newCommit.forEach(() => {
                newPrvMrkPercentOfAuM.push(privateMarketExposure);
            });

            if (viewYear && years[0] === latestYear) {
                // If data overlaps, merge values (commitment)
                commitment[0] += annualCommit[annualCommit.length - 1];
            } else if (
                !viewYear &&
                years[0] === 'Current'
            ) {
                // If data overlaps, merge values (commitment)
                commitment[0] += qtrCommit[qtrCommit.length - 1];
            }

            newCommit.pop();
            newNav.pop();
            newNavPercent.pop();
            newYears.pop();
            newPrvMrkPercentOfAuM.pop();

            emptyCommitment.pop();
            emptyNav.pop();

            commitment = emptyCommitment.concat(commitment);
            nav = emptyNav.concat(nav);
            navPercent = newNavPercent.concat(navPercent);
            years = newYears.concat(years);

            historicalCommitment = newCommit.concat(emptyFutureCommitment);
            historicalNav = newNav.concat(emptyFutureNav);
        }

        setDataPoints({
            nav,
            commitment,
            navPercent,
            navPercentUncertainty,
            years,
            historicalCommitment,
            historicalNav,
        });
        setPrvMrkPercentOfAuM(newPrvMrkPercentOfAuM);
    };

    useEffect(() => {
        if (
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_yr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_qtr')
        ) {
            setData();
        }
    }, [
        privateMarketExposure,
        viewYear,
        graphData.assetclass_qtr,
        graphData.assetclass_yr,
        historicalData,
        viewHistorical,
        viewHighLow,
    ]);

    const {
        nav,
        commitment,
        navPercent,
        navPercentUncertainty,
        years,
        historicalCommitment,
        historicalNav,
    } = data;
    const label = viewYear
        ? { step: 1, rotation: 'auto' }
        : { step: 1, rotation: 'auto' };

    const title =
        dateType === 'fiscal' && viewYear
            ? { text: 'Fiscal Years' }
            : { text: 'Years' };

    return (
        <div className='panel'>
            <Chart pannable={false} zoomable={false}>
                <ChartLegend
                    visible
                    position='bottom'
                    orientation='horizontal'
                />
                <ChartAxisDefaults majorGridLines={false} />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        axisCrossingValues={[0, 500]}
                        categories={years}
                        title={title}
                        labels={label}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        name='value'
                        title={{ text: `Value in ${currency}` }}
                    />
                    <ChartValueAxisItem
                        name='percent'
                        title={{ text: '% of Total Portfolio Value' }}
                    />
                </ChartValueAxis>
                <ChartSeries>
                    <ChartSeriesItem
                        name='Commitments'
                        type='column'
                        color={colorCombos[1].primary}
                        data={commitment}
                        stack={false}
                        axis='value'
                    />
                    <ChartSeriesItem
                        name='Historical Commitments'
                        type='column'
                        color={colorCombos[1].primary}
                        data={historicalCommitment}
                        stack={false}
                        axis='value'
                        opacity={0.5}
                    />
                    <ChartSeriesItem
                        name='NAV'
                        type='column'
                        color={colorCombos[0].primary}
                        data={nav}
                        stack={false}
                        axis='value'
                    />
                    <ChartSeriesItem
                        name='Historical NAV'
                        type='column'
                        color={colorCombos[0].primary}
                        data={historicalNav}
                        stack={false}
                        axis='value'
                        opacity={0.5}
                    />
                    <ChartSeriesItem
                        name='NAV %'
                        type='line'
                        color={colorCombos[2].primary}
                        data={navPercent}
                        axis='percent'
                    />
                    <ChartSeriesItem
                        name='Target'
                        type='line'
                        dashType='longDash'
                        color={colorCombos[1].primary}
                        markers={{ visible: false }}
                        data={prvMrkPercentOfAuM}
                        axis='percent'
                    />
                    <ChartSeriesItem
                        id='NAV % Uncertainty'
                        name='NAV % Uncertainty'
                        type='rangeArea'
                        data={navPercentUncertainty}
                        color={colorCombos[2].primary}
                        axis='percent'
                        fromField='min'
                        toField='max'
                    />
                </ChartSeries>
                <ChartTooltip />
            </Chart>
        </div>
    );
};

export default CommitmentNavGraph;
