import React, { Component, useContext } from 'react';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { LandingContext, PacingAnalysisContext } from 'context';
import { numberFormat, colorCombos, getDisplayDates } from 'utils';

type Props = {
    checkForRenamedStrategy: Function,
    dateType: string,
    graphData: any,
    privateMarketExposure: number,
    useForwardNav: boolean,
    viewYear: boolean,
};

class NavByStratGraphComponent extends Component<Props> {
    constructor(props) {
        super(props);
        const { privateMarketExposure } = props;
        this.state = {
            data: {},
            xValues: [],
            privateMarketExposure,
            prvMrkPercentOfAuM: [],
        };
    }

    componentDidMount() {
        const { graphData } = this.props;
        if (
            Object.prototype.hasOwnProperty.call(graphData, 'strategy_yr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'strategy_qtr')
        ) {
            this.setData();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { privateMarketExposure, data } = this.state;
        const nexPropsGraphData = nextProps.graphData;
        const { viewYear, graphData } = this.props;

        if (
            graphData.strategy_yr !== nexPropsGraphData.strategy_yr ||
            graphData.strategy_qtr !== nexPropsGraphData.strategy_qtr ||
            privateMarketExposure !== nextProps.privateMarketExposure ||
            viewYear !== nextProps.viewYear ||
            data !== nextState.data
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const {
            viewYear,
            graphData,
            privateMarketExposure: propsPrivateMarketExposure,
        } = this.props;
        const { privateMarketExposure } = this.state;

        if (
            graphData.strategy_yr !== prevProps.graphData.strategy_yr ||
            graphData.strategy_qtr !== prevProps.graphData.strategy_qtr ||
            privateMarketExposure !== propsPrivateMarketExposure ||
            prevProps.viewYear !== viewYear
        ) {
            this.setData();
        }
    }

    setData = () => {
        const {
            graphData,
            viewYear,
            privateMarketExposure,
            useForwardNav,
        } = this.props;

        let strategyData = {};
        if (viewYear) {
            strategyData = graphData.strategy_yr;
        } else {
            strategyData = graphData.strategy_qtr;
        }

        if (!strategyData) {
            return;
        }

        const portAum = strategyData['0'].slice(3);
        const holder = [];

        Object.keys(strategyData).forEach((key) => {
            if (strategyData[key][2] === 'NAV') {
                const values = strategyData[key].slice(3);
                values.forEach((value, index) => {
                    if (!(strategyData[key][0] in holder)) {
                        holder[strategyData[key][0]] = [];
                        holder[strategyData[key][0]].push(
                            value !== 0
                                ? numberFormat((value / portAum[index]) * 100)
                                : 0
                        );
                    } else if (value !== 0) {
                        holder[strategyData[key][0]].push(
                            numberFormat((value / portAum[index]) * 100)
                        );
                    } else {
                        holder[strategyData[key][0]].push(0);
                    }
                });
            }
        });
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                ...holder,
            },
        }));

        let years = [];
        let prvMrkPercentOfAuM = [];
        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
                for (let j = 0; j < graphData.year_labels.length; j++) {
                    prvMrkPercentOfAuM.push(privateMarketExposure);
                }
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                        prvMrkPercentOfAuM.push(privateMarketExposure);
                    }
                }
            }
        } else {
            ({ years, percent: prvMrkPercentOfAuM } = getDisplayDates(
                graphData.qtrdates,
                privateMarketExposure,
                useForwardNav
            ));
        }

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
            },
            xValues: years,
            privateMarketExposure,
            prvMrkPercentOfAuM,
        }));
    };

    generateColumns = (data) => {
        const { checkForRenamedStrategy } = this.props;
        const { prvMrkPercentOfAuM } = this.state;
        let columnGraphContent = null;
        let keyholder = null;

        if (data) {
            columnGraphContent = Object.keys(data).map((key, index) => {
                keyholder = key;
                let displayStrategy = key;

                if (key.includes('User Defined')) {
                    displayStrategy = checkForRenamedStrategy(key);
                }

                const color = colorCombos[index]
                    ? colorCombos[index].primary
                    : '';
                return (
                    <ChartSeriesItem
                        key={key}
                        name={key}
                        type='column'
                        stack
                        data={data[key]}
                        color={color}
                    >
                        <ChartSeriesItemTooltip
                            render={({ point }) =>
                                `${displayStrategy}: ${point.value}%`
                            }
                        />
                    </ChartSeriesItem>
                );
            });

            columnGraphContent.push(
                <ChartSeriesItem
                    key={keyholder + 1}
                    name='Yield'
                    type='line'
                    dashType='longDash'
                    color={colorCombos[1].primary}
                    markers={{ visible: false }}
                    data={prvMrkPercentOfAuM}
                />
            );
            return columnGraphContent;
        }

        return <div>Loading...</div>;
    };

    render() {
        const { data, xValues } = this.state;
        const { dateType, viewYear } = this.props;

        const label = viewYear
            ? { step: 1, rotation: 'auto' }
            : { step: 1, rotation: 'auto' };

        const title =
            dateType === 'fiscal' && viewYear
                ? { text: 'Fiscal Years' }
                : { text: 'Years' };

        return (
            <Chart pannable={false} zoomable={false}>
                <ChartLegend visible={false} />
                <ChartTooltip />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                        title={title}
                        categories={xValues}
                        labels={label}
                        majorGridLines={false}
                    />
                </ChartCategoryAxis>
                <ChartValueAxis>
                    <ChartValueAxisItem
                        title={{ text: '% of Total Portfolio Value' }}
                    />
                </ChartValueAxis>
                <ChartSeries>{this.generateColumns(data)}</ChartSeries>
            </Chart>
        );
    }
}

const NavByStratGraph = (props) => {
    const {
        checkForRenamedStrategy,
        graphData,
        pacingParameters: { dateType, privateMarketExposure },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);

    return (
        <NavByStratGraphComponent
            checkForRenamedStrategy={checkForRenamedStrategy}
            dateType={dateType}
            graphData={graphData}
            privateMarketExposure={privateMarketExposure}
            useForwardNav={useForwardNav}
            {...props}
        />
    );
};

export default NavByStratGraph;
