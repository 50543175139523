import { TotalsRowRenderer } from "Landing/components/agGridComponents";
import { NumericEditor, DatePicker } from "utils";

const FrameworkComponents = {
    numericEditor: NumericEditor,
    totalsRowRenderer: TotalsRowRenderer,
    datePicker: DatePicker,
};

export default FrameworkComponents;
