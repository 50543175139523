import {
    FormatNumberWithoutLock,
    FormatNumber,
    NumericEditor,
    numericValidator,
} from 'utils';
import { TotalsRowRenderer } from 'Landing/components';

/**
 * Column defintions for Commitment Schedule
 * For both the commitment schedule and
 * total portfolio net contributions
 *
 * @param {*} updateValue
 * @param {*} startYear
 * @param {*} endYear
 * @param {*} fiscalYearPlusOne
 */
export function TotalsColumnDefinitions(
    updateValue = null,
    startYear,
    endYear,
    fiscalYearPlusOne,
    toggleLock,
    optimizer = [],
    handleValueClick,
    targetExposureType
) {
    const tableDefinitions = [];
    tableDefinitions.push({
        headerName: '',
        valueFormatter: ({ value }) => {
            // default to 'Total Portfolio Net Cash Flows' if empty 
            if (!value || value == '') return 'Total Portfolio Net Cash Flows';
            return value;
        },
        field: 'strategy',
        pinned: 'left',
        editable: false,
        width: 250,
        resizable: false,
    });

    for (let i = startYear; i <= endYear; i++) {
        const year = fiscalYearPlusOne ? i + 1 : i;
        const stringYear = year.toString();
        if (year <= endYear) {
            const yearDefinition = {
                headerName: stringYear,
                field: stringYear,
                type: 'numericColumn',
                cellRenderer: 'formatNumberWithoutLock',
                cellRendererParams: () => {
                    return {
                        handleValueClick,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => {
                    return {
                        field: stringYear,
                        updateValue,
                    };
                },
                editable: (params) =>
                    params.node.rowPinned !== 'bottom' &&
                    targetExposureType !== 'noNewCommitments',
                onCellValueChanged: numericValidator,
            };
            tableDefinitions.push(yearDefinition);
        }
    }

    return tableDefinitions;
}

export function FrameworkComponents() {
    return {
        numericEditor: NumericEditor,
        formatNumberWithoutLock: FormatNumberWithoutLock,
        formatNumber: FormatNumber,
        totalsRowRenderer: TotalsRowRenderer,
    };
}
