import { addMonths, format, getQuarter } from "date-fns";
import moment from 'moment';

/**
 * Takes in a value and returns the formatted number
 * to the specified decimal place.
 *
 * @param {*} value
 * @param {number} decimalPlaces
 */
export function numberFormat(value, decimalPlaces = 2) {
    // Ensure value coming in is string to get length;
    const valueString = String(value);
    const stringLength = valueString.length;

    let formattedValue = parseFloat(
        (Math.round(valueString * 10000000) / 10000000).toFixed(decimalPlaces)
    );

    // Ensure deciaml and minus are maintained
    // even with no decimal values if it was typed
    // or when only a minus is typed
    // prevents duplicates of periods and minus signs
    if (valueString === '-.') {
        formattedValue = '-0.';
    } else if (valueString.charAt(stringLength - 1) === '.') {
        if (stringLength > 1) {
            const splits = valueString.split('.');
            if (splits.length === 2) {
                formattedValue += '.';
            } else {
                formattedValue = valueString.slice(0, -1);
            }
        } else {
            formattedValue = '.';
        }
    } else if (
        valueString.charAt(stringLength - 1) === '-' &&
        stringLength === 1
    ) {
        const splits = valueString.split('-');
        if (splits.length === 2) {
            formattedValue = '-';
        } else {
            formattedValue = valueString.slice(0, -1);
        }
    }

    return formattedValue;
}

/**
 * Returns strategyAllocations filtered by asset class
 *
 * @param {Array<StrategyAllocationType>} strategyAllocation
 * @param {string} assetClass
 */
export function filterStrategyAllocationByAssetClass(
    strategyAllocation,
    assetClass
) {
    return strategyAllocation.filter(({ assetClassShort }) => {
        return assetClassShort === assetClass;
    });
}

/**
 * Returns strategyAllocations filtered by asset class and strategy name (panelName)
 *
 * @param {Array<StrategyAllocationType>} strategyAllocation
 * @param {string} assetClass
 * @param {string} strategy
 */
export function filterStrategyAllocationByAssetClassAndStrategy(
    strategyAllocation,
    assetClass,
    strategy
) {
    return strategyAllocation.filter(({ assetClassShort, panelName }) => {
        return assetClassShort === assetClass && panelName === strategy;
    });
}

/**
 * Calculate the available remaining allocation out of 100
 * for a specific asset class
 *
 * @param {Array<StrategyAllocationType>} strategyAllocation
 * @param {string} assetClass
 * @param {string} strategy
 */
export function getAvailableAllocation(
    strategyAllocation,
    assetClass,
    strategy
) {
    const filteredStrategyAllocations = filterStrategyAllocationByAssetClass(
        strategyAllocation,
        assetClass
    );

    const totalsReducer = (accumulator, reducerStrategy) => {
        return (
            accumulator +
            (reducerStrategy.serverNames === strategy
                ? 0
                : reducerStrategy.alloc || 0)
        );
    };
    const currentAllocation = filteredStrategyAllocations.reduce(
        totalsReducer,
        0
    );

    return 100 - currentAllocation;
}

/**
 * Format number with thousands separator.
 *
 * @param {integer} input
 */
export function formatIntWithCommas(input, decimalPlaces = 0) {
    const value = parseFloat(input).toFixed(decimalPlaces);

    const parts = value.toString().split('.');

    if (parts[1] && parts[1].length > decimalPlaces) {
        parts[1] = parts[1].substring(0, decimalPlaces);
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function getNextQuarterStartDate(date = null) {
    const dateToCheck = date ? moment(date).toDate() : moment().toDate();

    const quarter =  Math.floor((dateToCheck.getMonth() + 3) / 3);

    let year = dateToCheck.getFullYear();

    let month = 0;
    if (quarter === 4) {
        year += 1;
    }

    switch (quarter) {
        case 1:
            month = '04';
            break;
        case 2:
            month = '07';
            break;
        case 3:
            month = '10';
            break;
        case 4:
            month = '01';
            break;
        default:
            break;
    }

    return `${year}-${month}-01`;
}

/**
 * Returns quarter from a date string
 * If no date is entered, current date will be used
 *
 * @param {string} date
 */
export function getQuarterFromDate(date = null) {
    const splits = date.split('-');
    const dateToCheck =
        new Date(splits[0], splits[1] - 1, splits[2]) || new Date();
    const quarter = Math.floor((dateToCheck.getMonth() + 3) / 3);

    let stringQtr = 0;
    switch (quarter) {
        case 1:
            stringQtr = 'Q1';
            break;
        case 2:
            stringQtr = 'Q2';
            break;
        case 3:
            stringQtr = 'Q3';
            break;
        case 4:
            stringQtr = 'Q4';
            break;
        default:
            break;
    }

    return stringQtr;
}

/**
 * Returns quarter and year from a date string
 * If no date is entered, current date will be used
 *
 * @param {string} date
 */
export function getQuarterAndYearFromDate(date = null) {
    const splits = date.split('-');
    
    const quarter = getQuarterFromDate(date);

    return `${quarter} ${splits[0]}`
}

/**
 * Calculate the end of the current quarter
 * based on the current date
 *
 */
export function getCurrQuarterEndDate() {
    const dateToCheck = new Date();
    const year = dateToCheck.getFullYear();
    const currMonth = dateToCheck.getMonth();

    let month = 0;
    let day = 0;

    switch (currMonth) {
        case 0:
        case 1:
        case 2:
            month = 2;
            day = 31;
            break;
        case 3:
        case 4:
        case 5:
            month = 5;
            day = 30;
            break;
        case 6:
        case 7:
        case 8:
            month = 8;
            day = 30;
            break;
        case 9:
        case 10:
        case 11:
            month = 11;
            day = 31;
            break;
        default:
            break;
    }

    return new Date(year, month, day);
}

/**
 * Format display dates for pacing analysis graphs
 *
 * @param {array} dates
 * @param {number} precentPrivateMarkets
 * @param {boolean} useForwardNav
 */
export function getDisplayDates(
    dates,
    precentPrivateMarkets = null,
    useForwardNav = false
) {
    const years = [];
    const percent = [];
    for (let j = 0; j < dates.length; j++) {
        const month = dates[j].substring(5, 7);
        let quarter = '';
        let displayDate = '';

        // forward NAV mean non quarter end
        // need to display full date for clarity
        // subesequent values are quarter end
        if (useForwardNav && j === 0) {
            const day = dates[j].substring(8, 10);
            const year = parseInt(dates[j], 10);

            displayDate = `${month}-${day}-${year}`;
        } else {
            const year = `${parseInt(dates[j], 10)}`.slice(-2);
            switch (month) {
                case '03':
                    quarter = '1Q';
                    break;
                case '06':
                    quarter = '2Q';
                    break;
                case '09':
                    quarter = '3Q';
                    break;
                case '12':
                    quarter = '4Q';
                    break;
                default:
                    break;
            }
            displayDate = `${quarter}${year}`;
        }

        years.push(displayDate);

        // only display percent of private market line
        // if value is defined
        if (precentPrivateMarkets >= 0) {
            percent.push(precentPrivateMarkets);
        }
    }

    // if the first doesn't have a qtr
    // or is the same as label
    if (years[0].length < 4 || years[0] == years[1]) {
        years[0] = 'Current';
    }

    return {
        years,
        percent,
    };
}

/**
 * Get quarter from only month
 *
 * @param {string} month
 */
export function getQuarterFromMonthString(month) {
    let quarter = 0;

    switch (month) {
        case 'January':
        case 'February':
        case 'March':
        case '01':
        case '02':
        case '03':
        case 1:
        case 2:
        case 3:
            quarter = 1;
            break;
        case 'April':
        case 'May':
        case 'June':
        case '04':
        case '05':
        case '06':
        case 4:
        case 5:
        case 6:
            quarter = 2;
            break;
        case 'July':
        case 'August':
        case 'September':
        case '07':
        case '08':
        case '09':
        case 7:
        case 8:
        case 9:
            quarter = 3;
            break;
        case 'October':
        case 'November':
        case 'December':
        case '10':
        case '11':
        case '12':
        case 10:
        case 11:
        case 12:
            quarter = 4;
            break;
        default:
            quarter = 4;
            break;
    }

    return quarter;
}

/**
 * Calculate the age of a fund from its vintage
 *
 * @param {number} vintage
 */
export function calculateFundAge(vintage = 0, reportDate) {
    const date = reportDate || new Date();
    const currentYear = date.getFullYear();

    return currentYear - vintage + 1;
}

/**
 * Reduce sliderValues to only contain strategies with allocation
 * instead of all strategies
 *
 * @param {*} underwritingDataset
 * @param {*} sliderValues
 * @param {*} checkForRenamedStrategy
 * @param {*} strategyAllocation
 */
export const trimSliderValues = (
    underwritingDataset,
    sliderValues,
    checkForRenamedStrategy,
    strategyAllocation
) => {
    const newSliderValues = [];
    const defaultSliders = [];

    [...sliderValues].forEach((strategyRow) => {
        // find strategy allocation
        const selectedStrategyAlloc = strategyAllocation.find(
            ({ serverNames }) => serverNames === strategyRow.strategy
        );

        if (selectedStrategyAlloc && selectedStrategyAlloc.selected) {
            newSliderValues.push(strategyRow);
        } else {
            defaultSliders.push(strategyRow);
        }
    });

    return { newSliderValues, defaultSliders };
};

/**
 * Generates a list for quarters giving an initial date
 *
 * @param {string} date
 * @param {number} yearsAdvanced
 */
export const getQuartersStartDate = (date, yearsAdvanced = 3) => {
    let quarters = [];
    let startDateObj = moment(date).toDate();
    const startDate = moment(startDateObj).format('YYYY-MM-DD');

    const firstQuarter = getQuarterFromDate(startDate);

    
    quarters.push(`${firstQuarter} ${startDateObj.getFullYear()}`);

    const yrs = yearsAdvanced * 4

    for (let i = 0; i < yrs; i++) {
        const nextQuarter = addMonths(startDateObj, 3);
        
        const q = getQuarterFromDate(format(nextQuarter, 'yyyy-MM-dd'));

        quarters.push(`${q} ${nextQuarter.getFullYear()}`);
        
        startDateObj = nextQuarter;
    }

    return quarters;
}

export const generateQuarters = (quarter, range, offset=0) => {

    let quarterNum = parseInt(quarter.split(' ')[0][1])
    let quarterYear = parseInt(quarter.split(' ')[1])

    let quartersList = []
    let quarterIdx = 1

    quartersList.push(`Q${quarterNum} ${quarterYear}`)

    for(let i=0; i<range + offset; i++) {
        let num = quarterNum + quarterIdx
        
        if ( num == 4 ) {
            quartersList.push(`Q${num} ${quarterYear}`);
            quarterYear = quarterYear + 1
            quarterIdx = 0
            quarterNum = 1
        } else if ( num > 4) {
            num -= 4
            quarterYear = quarterYear + 1
            quarterIdx = 1
            quarterNum = 1
            quartersList.push(`Q${num} ${quarterYear}`);
        } else {
            quartersList.push(`Q${num} ${quarterYear}`);
            quarterIdx+=1
        }
    }

    return quartersList.slice(offset);
}

export const getOffsetQuarter = (quarter, offset) => {
    const quarterNum = parseInt(quarter.split(' ')[0][1]);
    let quarterYear = parseInt(quarter.split(' ')[1]);

    let num = quarterNum + offset;

    if( num > 4) {
        quarterYear = +1;
        num -= 4;
    }

    return `Q${num} ${quarterYear}`
}

export const formatDisplayPercentage = ({ value, decimalPlace }) => {
    const formattedValue = numberFormat(value, decimalPlace);

    return `${formattedValue}%`
}
