import React, { useContext, useEffect, useState } from 'react';
import 'hammerjs';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { numberFormat, colorCombos, getDisplayDates } from 'utils';
import { LandingContext, PacingAnalysisContext } from 'context';

type Props = {
    viewYear: boolean,
};

const GrowthSensitivityGraph = ({ viewYear }: Props) => {
    const {
        graphData,
        pacingParameters: { dateType },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);

    const [years, setYears] = useState([]);
    const [ratios, setRatios] = useState({});

    const setData = () => {
        // Configure quarterly or yearly data
        let strategyData = {};
        if (viewYear) {
            strategyData = graphData.fund_yr_ratio;
        } else {
            strategyData = graphData.fund_qtr_ratio;
        }

        if (!strategyData) {
            return;
        }

        // Create and assign the data object
        const data = {};
        Object.keys(strategyData).forEach((key) => {
            if (
                strategyData[key][0].includes('AUM Growth Rate') ||
                strategyData[key][0] === 'NAV Ratio'
            ) {
                const plotName =
                    strategyData[key][0] === 'NAV Ratio'
                        ? 'Base'
                        : strategyData[key][0].substring(16);
                const values = strategyData[key].slice(1);
                const holder = [];

                values.forEach((value) => {
                    holder.push(numberFormat(value * 100));
                });

                data[plotName] = holder;
            }
        });

        // Create and set the date labels
        let newYears = [];
        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                newYears = graphData.year_labels;
            } else {
                let year = 0;
                for (let j = 0; j < graphData.model_qtrdates.length; j++) {
                    const newYear = parseInt(graphData.model_qtrdates[j], 10);
                    if (year !== newYear) {
                        newYears.push(
                            parseInt(graphData.model_qtrdates[j], 10)
                        );
                        year = newYear;
                    }
                }
            }
        } else {
            ({ years: newYears } = getDisplayDates(
                graphData.qtrdates,
                null,
                useForwardNav
            ));
        }

        setRatios(data);
        setYears(newYears);
    };

    useEffect(() => {
        setData();
    }, [graphData.fund_yr_ration, graphData.fund_qtr_ratio, viewYear]);

    const generateLines = (strategies) => {
        return Object.keys(strategies).map((key, index) => {
            const color = colorCombos[index] ? colorCombos[index].primary : '';
            const name = key === '' ? 'Base' : key;

            return (
                <ChartSeriesItem
                    color={color}
                    dashType={key === '' ? 'solid' : 'longDash'}
                    data={strategies[key]}
                    key={key}
                    markers={{ visible: false }}
                    name={name}
                    style={{
                        style: 'smooth',
                    }}
                    type='line'
                >
                    <ChartSeriesItemTooltip
                        render={({ point }) => `${name}: ${point.value}%`}
                    />
                </ChartSeriesItem>
            );
        });
    };

    const label = viewYear
        ? { step: 1, rotation: 'auto' }
        : { step: 1, rotation: 'auto' };

    const title =
        dateType === 'fiscal' && viewYear
            ? { text: 'Fiscal Years' }
            : { text: 'Years' };

    return (
        <Chart pannable={false} zoomable={false}>
            <ChartLegend visible position='custom' offsetX={60} offsetY={25} />
            <ChartTooltip />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    title={title}
                    categories={years}
                    majorGridLines={false}
                    labels={label}
                />
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem
                    title={{
                        text: 'NAV (% of total portfolio)',
                    }}
                />
            </ChartValueAxis>
            <ChartSeries>{generateLines(ratios)}</ChartSeries>
        </Chart>
    );
};

export default GrowthSensitivityGraph;
