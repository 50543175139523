import moment from 'moment';
import {
    NumericEditor,
    numberFormat,
    numericValidator,
    dropdownValidator,
} from 'utils';
import { calculateNumQuarter, getVintageDate } from 'ParameterLab/helpers';
import {
    UnderwritingTableActionButtons,
} from '../../components/UnderwritingTableColumnDefinitions/components'; // TODO: move this to utils or somewhere more usefull

function formatDisplayPercentage(params) {
    const { value, decimalPlace } = params;
    if (typeof value === 'undefined') {
        return '';
    }
    const formattedValue = numberFormat(value, decimalPlace);

    return params.colDef.field === 'currentTvm'
        ? `${formattedValue}x`
        : `${formattedValue}%`;
}

function formatDisplayStrategy(params) {
    const { value, checkForRenamedStrategy } = params;
    if (typeof value === 'undefined') {
        return '';
    }
    const formattedValue = checkForRenamedStrategy(value);

    return formattedValue;
}

const getDate = (qtr) => {
    switch (qtr) {
        case 'Q1':
            return '03-31';
        case 'Q2':
            return '06-30';
        case 'Q3':
            return '09-30';
        case 'Q4':
        default:
            return '12-31';
    }
};

const greaterThanTwoYears = (closingYear, qtr, reportDate) => {
    const qtrDate = getDate(qtr);
    const dateString = `${closingYear}-${qtrDate}`;
    const fullDate = moment(dateString);
    // const fullDate = moment(dateString, 'MM-DD-YYYY').format();
    const reportObj = moment(reportDate);
    // const reportObj = moment(reportDate, 'MM-DD-YYYY').format();
    const diff = reportObj.diff(fullDate, 'years');
    return diff >= 2;
};

const getIrrTvmStyling = (params, reportDate) => {
    if (params.node.rowPinned) {
        return {};
    }

    const id = params.colDef.field;
    const { data } = params;
    const { closingYear, commitQtr } = data;

    const isGreaterThanTwoYears = greaterThanTwoYears(
        closingYear,
        commitQtr,
        reportDate
    );

    if (
        (id === 'currentIrr' &&
            isGreaterThanTwoYears &&
            parseFloat(data.currentIrr) < 0) ||
        (id === 'currentTvm' &&
            isGreaterThanTwoYears &&
            parseFloat(data.currentTvm) < 0.95)
    ) {
        // red if vintage year is > 2 years from current report + IRR is negative OR
        // if vintage year is > 2 years from current report + TVM is < 0.95x
        return {
            backgroundColor: 'RGB(217, 0, 27, 0.25)',
        };
    }

    return {};
};

const getCellStyling = (params, reportDate = null) =>  {
    if (params.node.rowPinned) {
        return {};
    }
    const id = params.colDef.field;

    const objectStyling = {};

    // Blue if column is editable (Year 1-Year 4 styling handled in UnderwritingPaidInRenderer)
    if (params.colDef.editable) {
        objectStyling.color = '#268fb3';
    }

    if (params.data?.userEdited?.length && params.data?.userEdited?.includes(id)) {
        objectStyling.fontWeight = 'bold';
    } else {
        objectStyling.fontWeight = 'normal';
    }

    if (reportDate) {
        const {
            data: { closingYear, commitQtr },
            value,
        } = params;

        const vintageDate = getVintageDate(commitQtr, closingYear);
        const numYrsFromVinToCurrDate = closingYear
            ? calculateNumQuarter(vintageDate, reportDate) / 4
            : null;

        objectStyling.backgroundColor =
            parseFloat(value) < parseFloat(numYrsFromVinToCurrDate)
                ? 'RGB(0, 120, 153, 0.25)'
                : 'transparent';
    }

    return objectStyling;
}

const columnDefinition = (
    updateValue = null,
    resetRow = null,
    conversusAccess = false,
    reportDate = null,
    targetAllocationStrategies,
    userIsAdmin = null,
    checkForRenamedStrategy,
) => [
    {
        field: 'fundAge',
        hide: true,
    },
    {
        headerName: 'Fund Name',
        field: 'fundName',
        pinned: 'left',
        filter: true,
        editable: false,
    },
    {
        field: 'investmentId',
        hide: true,
    },
    {
        headerName: 'Closing Year',
        field: 'closingYear',
        pinned: 'left',
        onCellValueChanged: (event) => {
            const { newValue, column, data } = event;
            updateValue(newValue, column.colId, data);
            numericValidator(event);
        },
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({ field: 'closingYear', updateValue }),
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        initialWidth: 80,
    },
    {
        headerName: 'Closing Qtr',
        field: 'commitQtr',
        pinned: 'left',
        headerTooltip:
            'For existing commitments, Qtr Committed sets the quarter the commitment was made. For future commitments, the selection will determine what quarter commitments will be made in each year. Selecting "All" will split the commitments across all four quarters in each year. "All" is selected by default.',
        cellEditor: 'agSelectCellEditor',
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        cellEditorParams: () => {
            return {
                values: ['All', 'Q1', 'Q2', 'Q3', 'Q4'],
                field: 'commitQtr',
                updateValue,
            };
        },
        onCellValueChanged: (event) => {
            const { newValue, column, data } = event;
            updateValue(newValue, column.colId, data);
            dropdownValidator(event);
        },
        editable: true,
        cellStyle: getCellStyling,
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        field: 'assetClass',
        hide: true,
    },
    {
        headerName: 'Investment Type',
        field: 'investmentType',
        pinned: 'left',
        editable: false,
        initialWidth: 80,
    },
    {
        headerName: 'Sector',
        field: 'sector',
        pinned: 'left',
        editable: false,
        initialWidth: 80,
    },
    {
        headerName: 'Pacing Strategy',
        field: 'strategy',
        pinned: 'left',
        cellEditor: 'agSelectCellEditor',
        cellRenderer: formatDisplayStrategy,
        cellRendererParams: (params) => {
            return {
                data: params.data,
                checkForRenamedStrategy
            };
        },
        cellEditorParams: () => { 
            const strategies = targetAllocationStrategies.map(
                ({ strategy }) => checkForRenamedStrategy(strategy)
            );
           
            return {
                values: strategies,
                field: 'strategy',
                updateValue,
            };
        },
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        onCellValueChanged: (event) =>  {
            const { newValue, column, data, node, colDef } = event;
            updateValue(newValue, column.colId, data);
            dropdownValidator({ node, column, colDef, newValue });
        },
        initialWidth: 80,
    },
    {
        headerName: 'Current Performance',
        children: [
            {
                headerName: 'IRR',
                field: 'currentIrr',
                pinned: 'left',
                editable: false,
                cellRenderer: formatDisplayPercentage,
                cellRendererParams: (params) => {
                    const decimalPlace = 1;
                    return {
                        params,
                        decimalPlace,
                    };
                },
                cellStyle: (params) => getIrrTvmStyling(params, reportDate),
                initialWidth: 60,
                suppressMenu: true,
            },
            {
                headerName: 'TVM',
                field: 'currentTvm',
                pinned: 'left',
                editable: false,
                cellRenderer: formatDisplayPercentage,
                cellRendererParams: (params) => {
                    const decimalPlace = 1;
                    return {
                        params,
                        decimalPlace,
                    };
                },
                cellStyle: (params) => getIrrTvmStyling(params, reportDate),
                initialWidth: 60,
                suppressMenu: true,
            },
            {
                headerName: 'Commitment',
                field: 'currentCommmitment',
                pinned: 'left',
                editable: false,
                hide: true,
                initialWidth: 60,
                headerTooltip: 'In millions',
            },
            {
                headerName: 'Contributions',
                field: 'currentContributions',
                pinned: 'left',
                editable: false,
                hide: true,
                initialWidth: 60,
                headerTooltip: 'In millions',
            },
            {
                headerName: 'Distributions',
                field: 'currentDistributions',
                pinned: 'left',
                editable: false,
                hide: true,
                initialWidth: 60,
                headerTooltip: 'In millions',
            },
            {
                headerName: 'NAV',
                field: 'currentNAV',
                pinned: 'left',
                editable: false,
                initialWidth: 60,
                headerTooltip: 'In millions',
            },
            {
                headerName: 'Unfunded',
                field: 'currentUnfunded',
                pinned: 'left',
                editable: false,
                initialWidth: 60,
                headerTooltip: 'In millions',
            },
        ],
    },
    {
        field: 'scenario',
        filter: true,
        hide: true,
    },
    {
        headerName: 'Paid In',
        field: 'paidIn',
        headerTooltip: 'Values must be between 0 - 100',
        type: 'numericColumn',
        cellRenderer: formatDisplayPercentage,
        cellRendererParams: (params) => {
            const decimalPlace = 0;
            return {
                params,
                decimalPlace,
            };
        },
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'paidIn',
            updateValue,
        }),
        onCellValueChanged: numericValidator,
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Paid In',
        children: [
            {
                headerName: 'Year 1',
                field: 'rc1',
                headerTooltip: 'Values must be between 0 - 100',
                type: 'numericColumn',
                cellRenderer: 'UnderwritingPaidInRenderer',
                cellRendererParams: (params) => {
                    const decimalPlace = 0;
                    return {
                        params,
                        decimalPlace,
                        year: 1,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => ({ field: 'rc1', updateValue }),
                editable: ({ data: { fundAge } }) => !(fundAge > 2),
                onCellValueChanged: numericValidator,
                cellStyle: (params) => getCellStyling(params),
                filter: false,
                initialWidth: 80,
                suppressMenu: true,
            },
            {
                headerName: 'Year 2',
                field: 'rc2',
                headerTooltip: 'Values must be between 0 - 100',
                type: 'numericColumn',
                cellRenderer: 'UnderwritingPaidInRenderer',
                cellRendererParams: (params) => {
                    const decimalPlace = 0;
                    return {
                        params,
                        decimalPlace,
                        year: 2,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => ({ field: 'rc2', updateValue }),
                editable: ({ data: { fundAge } }) => !(fundAge >3),
                onCellValueChanged: numericValidator,
                cellStyle: (params) => getCellStyling(params),
                filter: false,
                initialWidth: 80,
                suppressMenu: true,
            },
            {
                headerName: 'Year 3',
                field: 'rc3',
                headerTooltip: 'Values must be between 0 - 100',
                type: 'numericColumn',
                cellRenderer: 'UnderwritingPaidInRenderer',
                cellRendererParams: (params) => {
                    const decimalPlace = 0;
                    return {
                        params,
                        decimalPlace,
                        year: 3,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => ({ field: 'rc3', updateValue }),
                editable: ({ data: { fundAge } }) => !(fundAge > 4),
                onCellValueChanged: numericValidator,
                cellStyle: (params) => getCellStyling(params),
                filter: false,
                initialWidth: 80,
                suppressMenu: true,
            },
            {
                headerName: 'Year 4',
                field: 'rc4',
                headerTooltip: 'Values must be between 0 - 100',
                type: 'numericColumn',
                cellRenderer: 'UnderwritingPaidInRenderer',
                cellRendererParams: (params) => {
                    const decimalPlace = 0;
                    return {
                        params,
                        decimalPlace,
                        year: 4,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => ({ field: 'rc4', updateValue }),
                editable: ({ data: { fundAge } }) => !(fundAge > 5),
                onCellValueChanged: numericValidator,
                cellStyle: (params) => getCellStyling(params),
                filter: false,
                initialWidth: 80,
                suppressMenu: true,
            },
            {
                headerName: 'Year 5+',
                field: 'rc5',
                headerTooltip: 'Values must be between 0 - 100',
                type: 'numericColumn',
                cellRenderer: formatDisplayPercentage,
                cellRendererParams: (params) => {
                    const decimalPlace = 0;
                    return {
                        params,
                        decimalPlace,
                    };
                },
                cellEditor: 'numericEditor',
                cellEditorParams: () => ({ field: 'rc5', updateValue }),
                onCellValueChanged: numericValidator,
                editable: true,
                cellStyle: (params) => getCellStyling(params),
                filter: false,
                initialWidth: 80,
                suppressMenu: true,
            },
        ],
    },
    {
        headerName: 'IRR',
        field: 'irr',
        headerTooltip: 'Values must be between -99 - 50',
        type: 'numericColumn',
        cellRenderer: formatDisplayPercentage,
        cellRendererParams: (params) => {
            const decimalPlace = 1;
            return {
                params,
                decimalPlace,
            };
        },
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'irr',
            updateValue,
        }),
        onCellValueChanged: numericValidator,
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Yield',
        field: 'yld',
        headerTooltip: 'Values must be between 0 - 20',
        type: 'numericColumn',
        cellRenderer: formatDisplayPercentage,
        cellRendererParams: (params) => {
            const decimalPlace = 1;
            return {
                params,
                decimalPlace,
            };
        },
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'yld',
            updateValue,
        }),
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        onCellValueChanged: numericValidator,
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Fund Life',
        field: 'fundLife',
        headerTooltip: 'Values must be between 1 - 100',
        cellStyle: (params) => getCellStyling(params, reportDate),
        type: 'numericColumn',
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'fundLife',
            updateValue,
        }),
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        onCellValueChanged: numericValidator,
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Distribution Delay',
        field: 'bow',
        headerTooltip: 'Values must be between 0.1 - 10',
        type: 'numericColumn',
        cellEditor: 'numericEditor',
        cellEditorParams: () => ({
            field: 'bow',
            updateValue,
        }),
        editable: true,
        cellStyle: (params) => getCellStyling(params),
        onCellValueChanged: numericValidator,
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Contributions Timing', // defaults to base, hide when not conversus permissioned
        field: 'contribTiming',
        hide: !conversusAccess && !userIsAdmin,
        cellEditor: 'agSelectCellEditor',
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        cellEditorParams: () => {
            return {
                values: ['Base', 'Fast', 'Slow'],
                field: 'contribTiming',
                updateValue,
            };
        },
        onCellValueChanged: ({ newValue, column, data }) => {
            updateValue(newValue, column.colId, data);
        },
        editable: ({ data: { scenario } }) => scenario === 'Base',
        // cellStyle: ({ data: { scenario } }) => ({
        //     color: scenario === 'Base' ? '#268fb3' : '#000000',
        // }),
        cellStyle: getCellStyling,
        filter: false,
        initialWidth: 80,
        suppressMenu: true,
    },
    {
        headerName: 'Actions',
        cellRenderer: UnderwritingTableActionButtons,
        cellRendererParams: () => ({
            handleReset: resetRow,
            value: 'default',
        }),
        editable: false,
        initialWidth: 80,
    },
    // columns for filtering purposes
    {
        field: 'fundStatus',
        suppressColumnsToolPanel: true,
        hide: true,
    },
    {
        field: 'lastUpdated',
        suppressColumnsToolPanel: true,
        hide: true,
    }
]

export default columnDefinition;