import { useContext, useEffect, useState } from "react";
import { UnderwritingContext, TargetAllocationContext } from "context";

const useTargetAllocationGrid = ({ api }) => {
    const { sliderValues, updateSliderValueByKey, resetSliderValue } = useContext(UnderwritingContext);
    const {
        strategyAllocation,
    } = useContext(TargetAllocationContext);

    const [data, setData] = useState([]);
    const [updatedAfterSort, setUpdatedAfterSort] = useState([]);

    const emptyUpdatedAfterSort = () => {
        setUpdatedAfterSort([]);
    };

    useEffect(() => {
        const newData = [];
        const selectedStrat = strategyAllocation
            .filter(({ selected }) => selected) // only selected

        selectedStrat.forEach((strat) => {
            const sliders = [...sliderValues]
                .filter(({ strategy }) => strategy === strat.serverNames)
                .map(({
                    bow,
                    fundLife,
                    irr,
                    paidIn,
                    yld,
                    rc1,
                    rc2,
                    rc3,
                    rc4,
                    rc5,
                    case: scenario,
                    numFunds,
                    ...rest
                }) => {
                    // add metadata to suppress auto sort on update
                    const foundLastEdited = updatedAfterSort.find((updatedRow) => (
                        updatedRow.strategy === rest.strategy
                        && updatedRow.scenario === scenario
                    ));
                    return ({
                        // add values of strategy allocations
                        assetClass: strat.assetClass,
                        bow: parseFloat(bow),
                        fundLife: parseFloat(fundLife),
                        irr: parseFloat(parseFloat(irr) * 100).toFixed(2),
                        paidIn: parseFloat(paidIn) * 100,
                        yld: parseFloat(yld) * 100,
                        rc1: parseFloat(rc1) * 100,
                        rc2: parseFloat(rc2) * 100,
                        rc3: parseFloat(rc3) * 100,
                        rc4: parseFloat(rc4) * 100,
                        rc5: parseFloat(rc5) * 100,
                        scenario,
                        numFunds,
                        lastUpdated: foundLastEdited,
                        ...rest, // add values of slider
                    })
                });
            newData.push(...sliders);
        })
        setData(newData);
    }, [sliderValues, strategyAllocation, updatedAfterSort]);

    const updateValue = (newValue, column, row) => {
        let value = newValue;
        if (column === 'numFunds') {
            resetSliderValue({ ...row, [column]: value }, true, true, false); // update all on contribTiming
            return;
        }
        if (column === 'contribTiming') {
            resetSliderValue({ ...row, [column]: value }, true, false, true); // update all on numFunds and scenarios
            return;
        }

        if (['irr', 'paidIn', 'yld', 'rc1', 'rc2', 'rc3', 'rc4', 'rc5'].includes(column)) {
            // parse value only if needed
            value = parseFloat(value) / 100.0;
        }

        // add metadata
        if (api) {
            let foundIndex = null;
            api.forEachNode((node, i) => {
                if (
                    node.rowIndex !== null                  // if is visible
                    && node.data.strategy === row.strategy  // if it's the updated row
                    && node.data.scenario === row.scenario  // if it's the same scenario
                ) {
                    foundIndex = node.rowIndex;
                }
            })
            if (foundIndex !== null) {
                setUpdatedAfterSort([
                    ... new Set([
                        ...updatedAfterSort,
                        { strategy: row.strategy, fundId: row.fundId, scenario: row.scenario, index: foundIndex },
                    ])
                ]);
            }
        }

        // update full slider with the new definition
        updateSliderValueByKey(row.strategy, column, value, row.scenario);
    }

    const resetRow = ({ strategy, scenario }) => {
        resetSliderValue({ strategy, scenario }); // reset only for selected scenario
    }

    return {
        data,
        updateValue,
        resetRow,
        emptyUpdatedAfterSort,
    }
}

export default useTargetAllocationGrid;