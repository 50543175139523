import { getQuarterFromDate } from 'utils';

const createFundSliderValues = (tableData, strategyParameters, fundSlider) => {
    const fundSliderValues = [];
    tableData.forEach(
        async ({
            PacingStrategy,
            Investment,
            Id,
            Vintage,
            ClosingDate,
            investmentId,
            AssetClass,
            InvestmentType,
            Sector,
            FundStatus,
            IRR,
            TVM,
            AdjNAV,
            Unfunded,
            Commitment,
            Contributions,
            Distributions,
            ...rest
            // userEdited, TODO: might not be needed
        }) => {
            const strategyDefaults = [...strategyParameters].filter(
                ({ strategy, contribTiming, numFunds }) =>
                    strategy === PacingStrategy &&
                    contribTiming === 'Base' &&
                    numFunds === 1
            );

            const foundFund = [...fundSlider].find((fund) => Id === fund.id);

            if (strategyDefaults) {
                let fundSliderHolder = {
                    investment: Investment,
                    id: String(Id),
                    investmentId: investmentId || null,
                    closingYear: ClosingDate
                        ? parseInt(ClosingDate.substring(0, 4))
                        : parseInt(Vintage),
                    commitQtr: ClosingDate
                        ? getQuarterFromDate(ClosingDate)
                        : 'Q2',
                    AssetClass,
                    InvestmentType,
                    Sector,
                    FundStatus,
                    currentIrr: IRR,
                    currentTvm: TVM,
                    currentNAV: AdjNAV,
                    currentUnfunded: Unfunded,
                    Commitment,
                    Contributions,
                    Distributions,
                    // userEdited, TODO: might not be needed
                };

                strategyDefaults.forEach((stratDefault) => {
                    const { case: scenario } = stratDefault;

                    const holder = {
                        strategy: stratDefault.strategy,
                        model: stratDefault.model,
                        case: stratDefault.case,
                        paidIn: parseFloat(stratDefault.paidIn),
                        irr: parseFloat(stratDefault.irr),
                        yld: parseFloat(stratDefault.yld),
                        fundLife: parseFloat(stratDefault.fundLife),
                        bow: parseFloat(stratDefault.bow),
                        rc1: parseFloat(stratDefault.rc1),
                        rc2: parseFloat(stratDefault.rc2),
                        rc3: parseFloat(stratDefault.rc3),
                        rc4: parseFloat(stratDefault.rc4),
                        rc5: parseFloat(stratDefault.rc5),
                        coinvesFlag: stratDefault.coinvestFlag || 0,
                        commitQtr: ClosingDate
                            ? getQuarterFromDate(ClosingDate)
                            : 'Q2',
                        userEdited: stratDefault.userEdited || [],
                    };

                    if (scenario === 'Base') {
                        fundSliderHolder.values = holder;
                    } else if (scenario === 'High') {
                        fundSliderHolder.valuesHigh = holder;
                    } else if (scenario === 'Low') {
                        fundSliderHolder.valuesLow = holder;
                    }
                });

                if (foundFund) {
                    // use already set values if any
                    fundSliderHolder = {
                        ...fundSliderHolder,
                        investment: Investment,
                        id: foundFund.id,
                        investmentId: foundFund.investmentId || null,
                        closingYear: foundFund.closingYear,
                        commitQtr: foundFund.commitQtr || fundSliderHolder.commitQtr,
                        userEdited: foundFund.userEdited || fundSliderHolder.userEdited
                    };
                    if (foundFund.values && Object.keys(foundFund.values).length !== 0) {
                        fundSliderHolder.values = {
                            ...fundSliderHolder.values,
                            ...foundFund.values,
                        }
                    }
                    if (foundFund.valuesHigh && Object.keys(foundFund.valuesHigh).length !== 0) {
                        fundSliderHolder.valuesHigh = {
                            ...fundSliderHolder.valuesHigh,
                            ...foundFund.valuesHigh,
                        }
                    }
                    if (foundFund.valuesLow && Object.keys(foundFund.valuesLow).length !== 0) {
                        fundSliderHolder.valuesLow = {
                            ...fundSliderHolder.valuesLow,
                            ...foundFund.valuesLow,
                        }
                    }
                }

                fundSliderValues.push(fundSliderHolder);
            }
        }
    );

    return fundSliderValues;
};

export default createFundSliderValues;
