import React, { Component, useContext } from 'react';
import {
    Chart,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts';

import { colorCombos, numberFormat, getDisplayDates } from 'utils';
import { LandingContext, PacingAnalysisContext, TargetAllocationContext } from 'context';

type Props = {
    graphData: any,
    privateMarketExposure: number,
    viewYear: boolean,
    dateType: string,
    useForwardNav: boolean,
    currency: string,
};

class CommitScheduleGraphComponent extends Component<Props> {
    constructor(props) {
        super(props);
        const { privateMarketExposure } = props;
        this.state = {
            data: {
                commitment: [],
                nav: [],
                navPercent: [],
                target: [],
                years: [],
            },
            privateMarketExposure,
            prvMrkPercentOfAuM: [],
        };
    }

    componentDidMount() {
        const { graphData } = this.props;

        if (
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_yr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'assetclass_qtr') &&
            Object.prototype.hasOwnProperty.call(graphData, 'fund_yr_hl') &&
            Object.prototype.hasOwnProperty.call(graphData, 'fund_qtr_hl')
        ) {
            this.setData();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { privateMarketExposure, data } = this.state;
        const nexPropsGraphData = nextProps.graphData;
        const { viewYear, graphData } = this.props;

        if (
            graphData.assetclass_yr !== nexPropsGraphData.assetclass_yr ||
            graphData.assetclass_qtr !== nexPropsGraphData.assetclass_qtr ||
            graphData.fund_yr_hl !== nexPropsGraphData.fund_yr_hl ||
            graphData.fund_qtr_hl !== nexPropsGraphData.fund_qtr_hl ||
            privateMarketExposure !== nextProps.privateMarketExposure ||
            viewYear !== nextProps.viewYear ||
            data !== nextState.data
        ) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { graphData, privateMarketExposure, viewYear } = this.props;

        if (
            prevProps.privateMarketExposure !== privateMarketExposure ||
            ((prevProps.graphData !== graphData ||
                prevProps.viewYear !== viewYear) &&
                Object.prototype.hasOwnProperty.call(
                    graphData,
                    'assetclass_yr'
                ) &&
                Object.prototype.hasOwnProperty.call(
                    graphData,
                    'assetclass_qtr'
                ) &&
                Object.prototype.hasOwnProperty.call(graphData, 'fund_yr_hl') &&
                Object.prototype.hasOwnProperty.call(graphData, 'fund_qtr_hl'))
        ) {
            this.setData();
        }
    }

    setData = () => {
        const {
            graphData,
            viewYear,
            privateMarketExposure,
            useForwardNav,
        } = this.props;

        let assetClassData = {};
        let highLowData = {};
        if (viewYear) {
            assetClassData = graphData.assetclass_yr;
            highLowData = graphData.fund_yr_hl;
        } else {
            assetClassData = graphData.assetclass_qtr;
            highLowData = graphData.fund_qtr_hl;
        }

        if (!assetClassData || !highLowData) {
            return;
        }

        const portAum = assetClassData['0'].slice(2);

        let years = [];
        const navHolder = {};
        const commitmentHolder = {};
        let highHolder = [];
        let lowHolder = {};

        Object.keys(assetClassData).forEach((key) => {
            const values = assetClassData[key].slice(2);
            if (assetClassData[key][1] === 'NAV') {
                values.forEach((value, index) => {
                    if (navHolder[index]) {
                        navHolder[index] += value;
                    } else {
                        navHolder[index] = value;
                    }
                });
            } else if (assetClassData[key][1] === 'Commitments') {
                values.forEach((value, index) => {
                    if (commitmentHolder[index]) {
                        commitmentHolder[index] += value;
                    } else {
                        commitmentHolder[index] = value;
                    }
                });
            }
        });

        Object.keys(highLowData).forEach((key) => {
            const values = highLowData[key].slice(5);
            if (highLowData[key][4] === 'NAV Ratio.High') {
                highHolder = values;
            } else if (highLowData[key][4] === 'NAV Ratio.Low') {
                lowHolder = values;
            }
        });

        const commitment = [];
        const navPercent = [];
        const highLowUncertainty = [];
        Object.keys(navHolder).forEach((key, index) => {
            commitment.push(numberFormat(commitmentHolder[key]));
            navPercent.push(
                numberFormat((navHolder[key] / portAum[key]) * 100, 1)
            );

            highLowUncertainty.push({
                min: numberFormat(lowHolder[index] * 100, 1),
                max: numberFormat(highHolder[index] * 100, 1),
            });
        });

        let prvMrkPercentOfAuM = [];
        if (viewYear) {
            if (
                Object.prototype.hasOwnProperty.call(graphData, 'year_labels')
            ) {
                years = graphData.year_labels;
                for (let j = 0; j < graphData.year_labels.length; j++) {
                    prvMrkPercentOfAuM.push(privateMarketExposure);
                }
            } else {
                let year = 0;
                for (let j = 0; j < graphData.qtrdates.length; j++) {
                    const newYear = parseInt(graphData.qtrdates[j], 10);
                    if (year !== newYear) {
                        years.push(parseInt(graphData.qtrdates[j], 10));
                        year = newYear;
                        prvMrkPercentOfAuM.push(privateMarketExposure);
                    }
                }
            }
        } else {
            ({ years, percent: prvMrkPercentOfAuM } = getDisplayDates(
                graphData.qtrdates,
                privateMarketExposure,
                useForwardNav
            ));
        }

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                commitment,
                navPercent,
                highLowUncertainty,
                years,
            },
            privateMarketExposure,
            prvMrkPercentOfAuM,
        }));
    };

    render() {
        const {
            data: { commitment, years, navPercent, highLowUncertainty },
            prvMrkPercentOfAuM,
        } = this.state;
        const { dateType, viewYear, currency } = this.props;
        const label = viewYear
            ? { step: 1, rotation: 'auto' }
            : { step: 1, rotation: 'auto' };

        const title =
            dateType === 'fiscal' && viewYear
                ? { text: 'Fiscal Years' }
                : { text: 'Years' };
        return (
            <div className='panel'>
                <Chart pannable={false} zoomable={false}>
                    <ChartLegend
                        visible
                        position='bottom'
                        orientation='horizontal'
                    />
                    <ChartAxisDefaults majorGridLines={false} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            axisCrossingValues={[0, 100]}
                            categories={years}
                            title={title}
                            labels={label}
                        />
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            name='value'
                            title={{ text: `Value in ${currency}` }}
                        />
                        <ChartValueAxisItem
                            name='percent'
                            title={{ text: '% of Total Portfolio Value' }}
                        />
                    </ChartValueAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            name='Commitments'
                            type='column'
                            color={colorCombos[1].primary}
                            data={commitment}
                            stack={false}
                            axis='value'
                        />
                        <ChartSeriesItem
                            name='NAV %'
                            type='line'
                            color={colorCombos[2].primary}
                            markers={{ visible: false }}
                            data={navPercent}
                            axis='percent'
                        />
                        <ChartSeriesItem
                            name='Target'
                            type='line'
                            dashType='longDash'
                            color={colorCombos[1].primary}
                            markers={{ visible: false }}
                            data={prvMrkPercentOfAuM}
                            axis='percent'
                        />
                        <ChartSeriesItem
                            id='navPercentUncertainty'
                            // name='Uncertainty'
                            type='rangeArea'
                            data={highLowUncertainty}
                            color={colorCombos[2].primary}
                            fromField='min'
                            toField='max'
                            axis='percent'
                        />
                    </ChartSeries>
                    <ChartTooltip />
                </Chart>
            </div>
        );
    }
}

const CommitScheduleGraph = (props) => {
    const {
        graphData,
        pacingParameters: { dateType, privateMarketExposure },
    } = useContext(PacingAnalysisContext);
    const { useForwardNav } = useContext(LandingContext);
    const { targetAllocation: { currency }, } = useContext(TargetAllocationContext);

    return (
        <CommitScheduleGraphComponent
            dateType={dateType}
            graphData={graphData}
            privateMarketExposure={privateMarketExposure}
            useForwardNav={useForwardNav}
            currency={currency}
            {...props}
        />
    );
};

export default CommitScheduleGraph;
