import React, {useState, useEffect, useRef} from 'react';
import {
    HistoricalInputs,
    SwitchButton
} from 'utils';
import { Route, NavLink } from 'react-router-dom';
import { CashFlowDownTurnGraph, NavDownTurnGraph } from '../graphs/DownTurnGraph';
import { DownturnInput } from '.';

const DownTurnWrapper = ({reportDate, runAnalysis, viewHistorical, setViewHistorical, handleHistoricalYearChange, numHistoricalYears}) => {

    const [viewYear, setViewYear] = useState(true);
    const [useCovidScenario, setUseCovidScenario] = useState(false);

    const navLinkRef = useRef(null)

    useEffect(() => {
        if(navLinkRef.current){
            navLinkRef.current.click();
        }
    }, [])

    const handleToggle = () => {
        setViewYear(!viewYear);
    };

    const handleHistoricalToggleDw = () => {
        setViewHistorical(!viewHistorical);
    };

    const handleHistoricalYearChangeDw = ({ target: {value} }) => {
        handleHistoricalYearChange(value);
    };

    return (
        <>
            <DownturnInput reportDate={reportDate} runAnalysis={runAnalysis}/>
            <div className='m-top'>
                <ul className='nav nav-tabs'>
                    <li className='nav-item'>
                        <div className='nav-link'>
                            <NavLink
                                activeClassName='panel-tab-active-2'
                                className='panel-tabs-2'
                                to='/pacing-analysis/downturn-scenario/cash-flows'
                                innerRef={navLinkRef}
                            >
                                Cash Flows
                            </NavLink>
                        </div>
                    </li>
                    <li className='nav-item'>
                        <div className='nav-link'>
                            <NavLink
                                activeClassName='panel-tab-active-2'
                                className='panel-tabs-2'
                                to='/pacing-analysis/downturn-scenario/nav'
                            >
                                NAV
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </div>
            <Route
                path='/pacing-analysis/downturn-scenario/cash-flows'
                render={() => (
                    <>
                        <SwitchButton 
                            isOn={viewYear}
                            handleToggle={handleToggle}
                            leftLabel='Yearly'
                            rightLabel='Quarterly'
                        />
                        <HistoricalInputs
                            viewHistorical={
                                viewHistorical
                            }
                            handleHistoricalToggle={
                                handleHistoricalToggleDw
                            }
                            handleHistoricalYearChange={
                                handleHistoricalYearChangeDw
                            }
                            numHistoricalYears={
                                numHistoricalYears
                            }
                        />
                        <CashFlowDownTurnGraph 
                            viewYear={viewYear}
                            viewHistorical={viewHistorical}
                            useCovidScenario={useCovidScenario}
                        />
                    </>
                )}
            />
            <Route
                path='/pacing-analysis/downturn-scenario/nav'
                render={() => (
                    <>
                        <SwitchButton 
                            isOn={viewYear}
                            handleToggle={handleToggle}
                            leftLabel='Yearly'
                            rightLabel='Quarterly'
                        />
                        <HistoricalInputs
                            viewHistorical={
                                viewHistorical
                            }
                            handleHistoricalToggle={
                                handleHistoricalToggleDw
                            }
                            handleHistoricalYearChange={
                                handleHistoricalYearChangeDw
                            }
                            numHistoricalYears={
                                numHistoricalYears
                            }
                        />
                        <NavDownTurnGraph
                            viewYear={viewYear}
                            viewHistorical={viewHistorical}
                        />
                    </>
                )}
            />
        </>
    )
}
const DownTurn = React.memo(DownTurnWrapper)

export default DownTurn
