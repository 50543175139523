import React, { useContext } from "react";
import { Box, FormInput, Grid, Section, Switch, Typography } from "@ssgglobal/techintnue";
import { useControllSwitch, useEndQuarterDates } from "./hooks";
import { LandingContext } from "context";

const RenderValues = (placeholder) => (value) => {
    if ((!value || value === '')) {
        return <Typography color="gray" sx={{ margin: 'revert' }}>{ placeholder }</Typography>;
    }
    return <Typography sx={{ margin: 'revert' }}>{ value }</Typography>;
}


const ScenariosSetup = () => {

    const {
        reportDate,
    } = useContext(LandingContext);

    const [contribActive, toggleContribActive] = useControllSwitch({ name: 'contribActive' });
    const [distribActive, toggleDistribActive] = useControllSwitch({ name: 'distribActive' });

    const [ quarterOptions ] = useEndQuarterDates({ reportDate })
    
    return (
        <Box
            sx={{ width: '100%' }}
        >
            <Section title="Cash Flow Downturn">
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}>
                        <Grid container direction="row" gap="24px" alignItems="center">
                            <Grid
                                item
                                xs={true}
                                sx={{
                                    '.MuiInputBase-root': {
                                        minHeight: 'unset !important',
                                        height: '38px',
                                    }
                                }}
                            >
                                <FormInput
                                    label="Contribution End Date"
                                    displayEmpty
                                    renderValue={RenderValues('Year, Quarter')}
                                    name="contribEndDate"
                                    type="dropdown"
                                    options={quarterOptions}
                                    optionValue="label"
                                    optionLabel="label"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={true}
                                sx={{
                                    '.MuiInputBase-root': {
                                        minHeight: 'unset !important',
                                        height: '38px',
                                    }
                                }}
                            >
                                <FormInput
                                    label="Downturn %"
                                    type="stepper"
                                    name="contribPercentage"
                                    step={0.01}
                                    min={0}
                                    max={100}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    marginTop: '20px',
                                    '& > .MuiFormControlLabel-root .MuiSwitch-root > .MuiSwitch-switchBase': {
                                        height: '24px',
                                    }
                                }}
                                
                            >
                                <Switch
                                    label="Activate Scenario" 
                                    isThemed
                                    checked={contribActive}
                                    onChange={toggleContribActive}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" gap="24px" alignItems="center">
                            <Grid
                                item
                                xs={true}
                                sx={{
                                    '.MuiInputBase-root': {
                                        minHeight: 'unset !important',
                                        height: '38px',
                                    }
                                }}
                            >
                                <FormInput
                                    label="Distribution End Date"
                                    displayEmpty
                                    renderValue={RenderValues('Year, Quarter')}
                                    name="distribEndDate"
                                    type="dropdown"
                                    options={quarterOptions}
                                    optionValue="label"
                                    optionLabel="label"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={true}
                                sx={{
                                    '.MuiInputBase-root': {
                                        minHeight: 'unset !important',
                                        height: '38px',
                                    }
                                }}
                            >
                                <FormInput
                                    label="Downturn %"
                                    type="stepper"
                                    name="distribPercentage"
                                    step={0.01}
                                    min={0}
                                    max={100}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                sx={{
                                    marginTop: '20px',
                                    '& > .MuiFormControlLabel-root .MuiSwitch-root > .MuiSwitch-switchBase': {
                                        height: '24px',
                                    }
                                }}
                            >
                                <Switch
                                    isThemed
                                    label="Activate Scenario"
                                    checked={distribActive}
                                    onChange={toggleDistribActive}
                                />
                            </Grid>
                        </Grid>
                </Box>
            </Section>
        </Box>
    )
};

export default ScenariosSetup;
