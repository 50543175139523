import { useContext, useEffect, useState } from "react";
import { LandingContext } from "context";
import { renderTotalsHandler, resetRowHandler } from "Landing/helpers";

const useLandingGrid = ({
    api,
}) => {
    const [data, setData] = useState([]);
    const {
        rawData,
        tableData,
        setTableData,
        tableDataPinnedBottomRowData,
        setTableDataPinnedBottomRowData,
        setPortfolioDropdown,
        updateTableDataValueByKey,
    } = useContext(LandingContext);

    const [updatedAfterSort, setUpdatedAfterSort] = useState([]);

    const emptyUpdatedAfterSort = () => {
        setUpdatedAfterSort([])
    };

    useEffect(() => {
        // map metadata for suppressing auto sort on recently updated rows
        const newData = [...tableData].map((row) => {
            const foundLastEdited = updatedAfterSort.find((updatedRow) => updatedRow.id === row.Id);
            return { ...row, lastUpdated: foundLastEdited }
        });
        // Add unique portfolio names to portfolioDropdown.
        const portfolioHolder = [];
        newData.forEach((row) => {
            if (!portfolioHolder.includes(row.Portfolio) && row.Portfolio) {
                portfolioHolder.push(row.Portfolio);
            }
        });
        setPortfolioDropdown(portfolioHolder);
        // calculate totals
        const pinnedBottomRowData = renderTotalsHandler(newData);
        setTableDataPinnedBottomRowData(pinnedBottomRowData);

        // update data
        setData(newData);
    }, [tableData, updatedAfterSort]);

    const updateValue = (newValue, column, row) => {
        if (api) {
            let foundIndex = null;
            api.forEachNode((node, i) => {
                if (
                    node.rowIndex !== null              // if is visible
                    && node.data.Id === row.Id          // if it's the updated row
                ) {
                    foundIndex = node.rowIndex;
                }
            })
            if (foundIndex !== null) {
                setUpdatedAfterSort([
                    ... new Set([
                        ...updatedAfterSort,
                        { id: row.Id, fundId: row.investmentId, index: foundIndex },
                    ])
                ]);
            }
        }
        updateTableDataValueByKey(row.investmentId || row.Id, column, newValue);
    }

    // remove row
    const removeRow = (data) => {
        const newTableData = tableData.filter((row) => (
            row.Id !== data.Id
        ));
        setTableData(newTableData);
    };

    // reset row
    const resetRow = (data) => {
        const newData = resetRowHandler(data, tableData, rawData);
        setTableData(newData);
    };

    return {
        data,
        totalData: [...tableDataPinnedBottomRowData] || [],
        updateValue,
        removeRow,
        resetRow,
        emptyUpdatedAfterSort,
    }
}

export default useLandingGrid;
