import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Button, FormProvider, useForm } from '@ssgglobal/techintnue';
import { Container, Row } from 'reactstrap';
import { BottomNav, PageTitle } from 'utils';
import ScenariosSetup from './ScenariosSetup';
import GraphSection from './GraphSection';

import { getPortfolioHistoricalCashFlows, postAnalysis } from 'api';
import { PacingAnalysisContext, LandingContext, AuthContext, UnderwritingContext } from 'context';
import { useGatherParams } from '../PacingAnalysis/components/PacingParameters/hooks';

const Documentation = ({ onClick }) => {
    return null;
    // return (
    //     <Button theme="info" onClick={onClick}>
    //         Documentation
    //     </Button>
    // );
}

const ScenariosPage = () => {
    const {
        setHistoricalData,
        setGraphData,
        setPacingParametersKeyValue,
        pacingParameters: {
            downturnContribActive,
            downturnContribEndDate,
            downturnContribPercentage,
            downturnDistribActive,
            downturnDistribEndDate,
            downturnDistribPercentage,
        }
    } = useContext(PacingAnalysisContext);
    const { user } = useContext(AuthContext);

    const { isOmniImport } = useContext(LandingContext);
    const { handleSave } = useContext(UnderwritingContext);

    const useFormMethods = useForm({
        defaultValues: {
            contribActive: downturnContribActive || false,
            contribEndDate: downturnContribEndDate,
            contribPercentage: downturnContribPercentage,
            distribActive: downturnDistribActive || false,
            distribEndDate: downturnDistribEndDate,
            distribPercentage: downturnDistribPercentage,
        },
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    });

    const [loading, setLoading] = useState(false);
    const { getParams, gatherHistoricalParams } = useGatherParams();

    useEffect(() => {
        if (loading) {
            Swal.showLoading();
            getAnalysis();
            return;
        }
        Swal.close();
    }, [loading])

    const getAnalysis = async () => {
        // TODO: set data to graph and probably set values on PacingAnalysis context
        const params = getParams();
        try {
            const results = await postAnalysis(params);
            let historicalParams = undefined;
            if (isOmniImport && user.omni_import_access) {
                historicalParams = await gatherHistoricalParams(5);
            }

            if (historicalParams?.investmentId.length > 0) {
                const historical = await getPortfolioHistoricalCashFlows(
                    historicalParams
                );
                await setHistoricalData(historical);
            }

            await setGraphData(results);
        } catch (e) {
            setLoading(false)
            Swal.fire({
                type: 'error',
                html:
                    'Something went wrong trying to run Analysis. ',
            });
        }
        setLoading(false)
    }

    const onSubmit = async (values) => {
        const {
            contribActive,
            contribEndDate,
            contribPercentage,
            distribActive,
            distribEndDate,
            distribPercentage,
        } = values;

        // parse to floats
        const contribPct = parseFloat(contribPercentage) || 0.0;
        const distribPct = parseFloat(distribPercentage) || 0.0;

        setPacingParametersKeyValue('downturnContribActive', contribActive);
        setPacingParametersKeyValue('downturnContribEndDate', contribEndDate || '');
        setPacingParametersKeyValue('downturnContribPercentage', contribActive ? contribPct : 0);

        setPacingParametersKeyValue('downturnDistribActive', distribActive);
        setPacingParametersKeyValue('downturnDistribEndDate', distribEndDate || '');
        setPacingParametersKeyValue('downturnDistribPercentage', distribActive ? distribPct : 0);
        setLoading(true);
    }

    return (
        <Container fluid>
            <PageTitle
                title='Scenarios'
                handleDocumentation={() => console.log('no save')}
                handleSave={() => { handleSave() }}
                render={() => <Documentation onClick={() => { console.log('documnetation') }} />}
                isPacing
            />
            <FormProvider useFormMethods={useFormMethods}>
                <Row className='m-0 bg-white'>
                    <ScenariosSetup />
                </Row>
                <Row className='mx-0 my-4 p-3 bg-white section-border'>
                    <GraphSection runAnalysis={onSubmit} />
                </Row>
            </FormProvider>
            <BottomNav backTarget="/parameter-lab" nextTarget="/pacing-analysis/quarterly-nav" />
        </Container>
    );
}

export default ScenariosPage;