// @flow
import React, { Component, useContext } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { getAllHistoricalCashFlows } from 'api';
import {
    LandingContextType,
    PacingContextType,
    StaticsContextType,
    TargetAllocationContextType,
    UnderwritingContextType,
} from 'types';
import { getQuarterFromDate } from 'utils';
import {
    AuthContext,
    LandingContext,
    PacingContext,
    StaticsContext,
    TargetAllocationContext,
} from 'context';
import {
    TableDataColumnDefinitions,
    FrameworkComponents,
    ReadOnlyTableDataColumnDefinitions,
    ReadOnlyFrameworkComponents,
    ExistingCommitmentTableLegend,
} from './agGridComponents';
import {
    resetSelectedRowHandler,
    renderTotalsHandler,
    updateValueHandler,
    resetRowHandler,
    setFilterHandler,
} from '../helpers';
import { RawDataModal, ResetRowModal, ReportDateModal } from '../modals';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import LandingGrid from './LandingGrid';

const uuidv4 = require('uuid/v4');

type Props = {
    getDefaultPacingStrategy: Function,
    landingContext: LandingContextType,
    targetAllocationContext: TargetAllocationContextType,
    pacingContext: PacingContextType,
    staticsContext: StaticsContextType,
    updateRawData: Function,
    userSpiClientId: number | null,
    isTrialUser: Boolean,
};

class TableDataGridComponent extends Component<Props> {
    constructor(props) {
        super(props);
        const {
            pacingContext: { pacingClientReadOnly },
            userSpiClientId,
            isTrialUser,
        } = props;

        this.state = {
            defaultColDef: {
                resizable: true,
                sortable: true,
                filter: true,
                editable: !pacingClientReadOnly && !userSpiClientId,
                // menuTabs: ['filterMenuTab'],
                overlayLoadingTemplate: '<span>Loading Pacings...</span>',
            },
            rawDataIsOpen: false,
            resetRemoveRowModalIsOpen: false,
            reportDateModalIsOpen: false,
            tableRowToResetRemove: {},
            filterModel: {},
            editReportDates: false,
            colDefState: null,
        };

        this.gridRef = React.createRef(null);
    }

    componentWillUnmount() {
        const {
            landingContext: {
                setTableData,
                tableData,
                reportDate,
                yearlyHistorical,
                setHistoricalExportData,
            },
            getDefaultPacingStrategy,
        } = this.props;

        const newTableData = [...tableData].map((row) => {
            const holder = { ...row };
            const { InvestmentType, AssetClass, Sector, SubSector } = row;

            if (
                row.PacingStrategy === '' ||
                row.PacingStrategy === '(Select a Strategy)'
            ) {
                holder.PacingStrategy = getDefaultPacingStrategy(
                    InvestmentType,
                    AssetClass,
                    Sector,
                    SubSector
                );
            }

            return holder;
        });

        setTableData(newTableData);

        let newHistoricalParams = {};

        newTableData.forEach(
            ({
                investmentId,
                Vintage,
                PortfolioId,
                ClosingDate,
                Investment,
            }) => {
                const calcClosingQuarter = ClosingDate
                    ? getQuarterFromDate(ClosingDate)
                    : 'Q2';

                const addRow = {
                    investmentId: investmentId,
                    fundVintage: Vintage,
                    commitmentQuarter: calcClosingQuarter,
                    reportDate: reportDate,
                    portfolioId: PortfolioId,
                    yearly: yearlyHistorical,
                };

                newHistoricalParams[Investment] = addRow;
            }
        );

        const histData = getAllHistoricalCashFlows(newHistoricalParams);
        setHistoricalExportData(histData);
    }

    /**
     * Initialize the grid api
     */
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        //params.api.sizeColumnsToFit();
    };

    toggleModal = ({ target: { name } }) => {
        const { [name]: value } = this.state;
        this.setState({ [name]: !value });
    };

    /**
     * Takes the user selected row and resets the tableData
     * with that row
     */
    resetSelectedRow = async (data) => {
        // Not sure how to trigger this
        const { tableRowToResetRemove } = this.state;
        const {
            updateRawData,
            landingContext: { rawData, tableData, setTableData },
            targetAllocationContext: { uuidToResetRemove },
        } = this.props;
        updateRawData(data);

        const newTableData = await resetSelectedRowHandler(
            tableData,
            tableRowToResetRemove,
            rawData,
            uuidToResetRemove
        );

        setTableData(newTableData);
        this.setState({ resetRemoveRowModalIsOpen: false });
    };

    /**
     * Add a new row to current tableData.
     * Sets a uuid to distinguish between other
     * manually added rows
     */
    addRowToTable = () => {
        const { reportDateModalIsOpen } = this.state;
        const { addInvestmentTableData, tableData } = this.props.landingContext;
        addInvestmentTableData();
        this.setState({
            reportDateModalIsOpen:
                tableData.length === 0 ? true : reportDateModalIsOpen,
        });
    };

    resizeTable = () => {
        //this.gridApi.sizeColumnsToFit();
    };

    columnVisibleHandler = (e) => {
        const {
            landingContext: { setExistingCommitmentsColumnsDefs },
        } = this.props;
        setExistingCommitmentsColumnsDefs(e.api.getColumnDefs())
    }

    render() {
        const {
            rawDataIsOpen,
            reportDateModalIsOpen,
            resetRemoveRowModalIsOpen,
        } = this.state;
        const {
            landingContext: {
                rawData,
                reportDate,
                setReportDate,
                tableData,
            },
            pacingContext: { pacingClientReadOnlyAndSpiClientId },
            isTrialUser,
            loadedSavedPacing,
        } = this.props;

        const isReadOnly = pacingClientReadOnlyAndSpiClientId();

        return (
            <div className='panel section-border'>
                <Row>
                    <ExistingCommitmentTableLegend />
                    <Col className='text-right' xs={6}>
                        {reportDate === '----/--/--' && (
                            <Button
                                name='addReportDateButton'
                                className='primary-button'
                                onClick={() =>
                                    this.toggleModal({
                                        target: {
                                            name: 'reportDateModalIsOpen',
                                        },
                                    })
                                }
                            >
                                Add Report Date
                            </Button>
                        )}
                        {!isReadOnly && (
                            <Tooltip
                                placement='top-start'
                                title={
                                    isTrialUser && tableData.length === 10
                                        ? 'Pacing Trial is limited to ten funds'
                                        : ''
                                }
                            >
                                <Button
                                    name='addFundIsOpen'
                                    className='primary-button'
                                    onClick={this.addRowToTable}
                                    disabled={
                                        isTrialUser && tableData.length === 10
                                    }
                                >
                                    Add Fund Manually
                                </Button>
                            </Tooltip>
                        )}
                        {/* Button was removed but code was requested to be kept intact */}
                        {/* <Button
                            name='rawDataIsOpen'
                            className='primary-button'
                            onClick={this.toggleModal}
                        >
                            View Raw Data
                        </Button>{' '} */}
                        <br />
                        {reportDate ? `Report Date: ${reportDate}` : ''}
                        {reportDate ? (
                            <IconButton
                                size='small'
                                onClick={() =>
                                    this.toggleModal({
                                        target: {
                                            name: 'reportDateModalIsOpen',
                                        },
                                    })
                                }
                                style={{
                                    top: '-1px',
                                    color: '#007899'
                                }}
                            >
                                <Edit />
                            </IconButton>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
                <div
                    className='ag-theme-balham'
                    style={{
                        position: 'relative',
                        height: '500px',
                        width: '100%',
                    }}
                >
                    <LandingGrid
                        isReadOnly={isReadOnly}
                    />
                    {/* <AgGridReact
                        ref={this.gridRef}
                        columnDefs={this.state.colDefState}
                        defaultColDef={defaultColDef}
                        enableRangeSelection
                        components={frameworkComponents}
                        // onCellValueChanged={this.onCellValueChanged}
                        onGridReady={this.onGridReady}
                        onPasteEnd={this.renderTotals}
                        onGridSizeChanged={this.resizeTable}
                        onColumnVisible={this.columnVisibleHandler}
                        pinnedBottomRowData={tableDataPinnedBottomRowData}
                        rowData={tableData}
                        enterMovesDown
                        singleClickEdit
                        stopEditingWhenCellsLoseFocus={true}
                        suppressExcelExport
                        suppressCsvExport
                        suppressMenuHide
                    /> */}
                </div>
                <div>
                    <RawDataModal
                        rawData={rawData}
                        isOpen={rawDataIsOpen}
                        toggleModal={() =>
                            this.toggleModal({
                                target: { name: 'rawDataIsOpen' },
                            })
                        }
                    />
                    <ReportDateModal
                        isOpen={reportDateModalIsOpen}
                        reportDate={reportDate}
                        setReportDate={setReportDate}
                        loadedSavedPacing={loadedSavedPacing}
                        toggleModal={() =>
                            this.toggleModal({
                                target: { name: 'reportDateModalIsOpen' },
                            })
                        }
                    />
                    {resetRemoveRowModalIsOpen && (
                        <ResetRowModal
                            rawData={rawData}
                            isOpen={resetRemoveRowModalIsOpen}
                            toggleModal={() =>
                                this.toggleModal({
                                    target: {
                                        name: 'resetRemoveRowModalIsOpen',
                                    },
                                })
                            }
                            resetSelectedRow={this.resetSelectedRow}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const TableDataGrid = (props) => {
    const landingContext = useContext(LandingContext);
    const pacingContext = useContext(PacingContext);
    const staticsContext = useContext(StaticsContext);
    const targetAllocationContext = useContext(TargetAllocationContext);
    const { userSpiClientId, isTrialUser } = useContext(AuthContext);

    return (
        <TableDataGridComponent
            landingContext={landingContext}
            pacingContext={pacingContext}
            staticsContext={staticsContext}
            targetAllocationContext={targetAllocationContext}
            userSpiClientId={userSpiClientId}
            isTrialUser={isTrialUser}
            {...props}
        />
    );
};

export default TableDataGrid;
