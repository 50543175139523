import { FormatNumber } from 'utils';

// READ ONLY
export const ReadOnlyTableDataColumnDefinitions = [
    {
        headerName: 'Portfolio',
        field: 'Portfolio',
        hide: true,
    },
    {
        headerName: 'Investment',
        field: 'Investment',
        pinned: 'left',
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 200,
    },
    {
        headerName: 'Vintage',
        field: 'Vintage',
        type: 'numericColumn',
        filter: 'agNumberColumnFilter',
        initialWidth: 100,
    },
    {
        headerName: 'Asset Class',
        field: 'AssetClass',
        initialWidth: 150,
    },
    {
        headerName: 'Investment Type',
        field: 'InvestmentType',
        initialWidth: 100,
    },
    {
        headerName: 'Sector',
        field: 'Sector',
        initialWidth: 150,
    },
    {
        headerName: 'Sub-Sector',
        field: 'SubSector',
        initialWidth: 150,
    },
    {
        headerName: 'Geography',
        field: 'Geography',
        initialWidth: 150,
    },
    {
        headerName: 'Commitment',
        field: 'Commitment',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 100,
    },
    {
        headerName: 'Contributions',
        field: 'Contributions',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 100,
    },
    {
        headerName: 'Distributions',
        field: 'Distributions',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 100,
    },
    {
        headerName: 'AdjNAV',
        field: 'AdjNAV',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 100,
    },
    {
        headerName: 'Unfunded',
        field: 'Unfunded',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 100,
    },
    {
        headerName: 'IRR (%)',
        field: 'IRR',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
                decimal: 2,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 75,
    },
    {
        headerName: 'TVM (x)',
        field: 'TVM',
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        cellRenderer: FormatNumber,
        cellRendererParams: (params) => {
            return {
                data: params.data,
                decimal: 2,
            };
        },
        pinnedRowCellRenderer: 'totalsRowRenderer',
        pinnedRowCellRendererParams: { style: { fontWeight: 'bold' } },
        initialWidth: 75,
    },
];
