import { Sector, SubSector, Geography } from 'data';
import {
    FormatNumber,
    numericValidator,
    dropdownValidator,
    dateSort,
    dateFilter,
} from 'utils';
import moment from 'moment';

import { TableDataActionButtons, TotalsRowRenderer } from 'Landing/components/agGridComponents';

/**
 * Determine if cell has been edited for styling logic
 * 
 * @param {*} params 
 * @returns {boolean}
 */
const userEditedIncludes = (params) => {
    const row = params.data;
    const id = params.colDef.field;

    let includes = false;

    if (row?.userEdited?.length) {
        if (row.userEdited.includes(id)) {
            includes = true;
        }
    }

    return includes;
};

/**
 * Determines if the LastNavDate of a fund has 3 quarters of lag
 *
 * @param {*} params
 */
const calculateLag = (navDate, currentYear) => {
    const navDateMinusDashes = navDate.replace(/-/g, '');
    const navDateAsInteger = parseInt(navDateMinusDashes, 10);
    const today = parseInt(moment().format('YYYYMMDD'));
    const lastYear = currentYear - 1;

    let quarterEndDates = [
        `${currentYear}0331`,
        `${
            today <= parseInt(`${currentYear}0331`, 10) ? lastYear : currentYear
        }0630`,
        `${
            today <= parseInt(`${currentYear}0630`, 10) ? lastYear : currentYear
        }0930`,
        `${
            today <= parseInt(`${currentYear}0930`, 10) ? lastYear : currentYear
        }1231`,
    ];

    const nineMonthsAgo = moment()
        .subtract(9, 'months')
        .format()
        .split('T')[0]
        .replace(/-/g, '');

    if (nineMonthsAgo.includes(lastYear)) {
        quarterEndDates = quarterEndDates.filter((date) =>
            date.includes(lastYear)
        );
    }

    const threeQuartersAgo = quarterEndDates.find(
        (date) => parseInt(date, 10) >= parseInt(nineMonthsAgo, 10)
    );

    if (navDateAsInteger < parseInt(threeQuartersAgo, 10)) {
        return true;
    }

    return false;
};

function getCellStyling(params, useForwardNav = false) {
    if (params.node.rowPinned) {
        return {};
    }

    let cellValue = params.value;
    const row = params.data;
    const id = params.colDef.field;

    const currentYear = new Date().getFullYear();

    let fundHasLag = false;
    if (row.LastNavDate) {
        fundHasLag = calculateLag(row.LastNavDate, currentYear);
    }

    const inputCellStyle = {};
    inputCellStyle.backgroundColor = 'RGB(255,255,255)';

    if (params.colDef.editable) {
        inputCellStyle.color = '#268fb3';
    }

    const userEdited = userEditedIncludes(params);
    if (userEdited) {
        inputCellStyle.fontWeight = 'bold';
    } else {
        inputCellStyle.fontWeight = 'normal';
    }

    // Columns that must contain only numeric chars
    const mustBeNumeric = [
        'Commitment',
        'Contributions',
        'Distributions',
        'AdjNAV',
        'Unfunded',
        'IRR',
        'TVM',
    ];

    // numericValidator changes cellValue to ERROR if it contains non-numeric chars
    if (mustBeNumeric.includes(id) && cellValue && cellValue === 'ERROR') {
        // if numeric cell contains non-numeric chars, turn text color red
        inputCellStyle.color = 'rgba(220,53,69,1)';
    }

    if (id === 'IRR' || id === 'TVM') {
        return inputCellStyle;
    }

    if (id === 'Vintage') {
        cellValue = parseFloat(cellValue);
        if (cellValue < 1970) {
            inputCellStyle.backgroundColor = 'rgba(255,27,0,0.35)';
        }
    }

    if (
        cellValue === '' ||
        cellValue === '(Blank)' ||
        typeof cellValue === 'undefined'
    ) {
        // Orange if missing value
        inputCellStyle.backgroundColor = 'RGB(247, 173, 33, 0.25)';
    }
    if (parseFloat(cellValue) < 0) {
        // Green if value is negative
        if (id !== 'ForwardNAV' && id !== 'AdjNAV') {
            inputCellStyle.backgroundColor = 'RGB(120, 163, 0, 0.25)';
        }
    }
    if (
        row.ClosingDate &&
        row.ClosingDate !== '' &&
        parseInt(row.ClosingDate.substr(0, 4)) < currentYear - 8 &&
        row.Unfunded > 0
    ) {
        // Blue if Closing Date is at least 10 years old and Unfunded is positive
        if (
            id === 'Unfunded' &&
            parseFloat(row.Unfunded) === parseFloat(cellValue)
        ) {
            inputCellStyle.backgroundColor = 'RGB(0, 120, 153, 0.25)';
        }
    }
    if (!row.ClosingDate && row.ClosingDate === '') {
        if (row.Vintage < currentYear - 8 && row.Unfunded > 0) {
            // Blue if vintage is at least 10 years old and Unfunded is positive
            if (
                id === 'Unfunded' &&
                parseFloat(row.Unfunded) === parseFloat(cellValue)
            ) {
                inputCellStyle.backgroundColor = 'RGB(0, 120, 153, 0.25)';
            }
        }
    }
    if (
        id === 'ForwardNAV' &&
        useForwardNav &&
        row.FundStatus === 'Active' &&
        fundHasLag
    ) {
        // yellow if fund is active but has a 3 quarter lag in last nav date
        inputCellStyle.backgroundColor = '#fbf28c';
    }

    return inputCellStyle;
}

const insertEmptyValue = (listOfValues) => {
    return listOfValues ? ['', ...listOfValues] : [];
};

export const TableDataColumnDefinitions = (
    updateValue = null,
    removeRow = null,
    resetRow = null,
    useForwardNav = false,
    attributes = [],
    portfolioDropdown = [],
    isSSGUser = false
) => {
    const colDefs = [
        {
            headerName: 'Portfolio',
            field: 'Portfolio',
            cellStyle: getCellStyling,
            hide: true,
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellEditor: 'agSelectCellEditor',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    field: 'Portfolio',
                    updateValue,
                    values: insertEmptyValue(portfolioDropdown),
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            initialWidth: 130,
        },
        {
            headerName: 'Investment',
            field: 'Investment',
            cellStyle: getCellStyling,
            equals: (investment1, investment2) => {
                return investment1 !== investment2
            },
            pinned: 'left',
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    field: 'Investment',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
            },
            initialWidth: 200,
        },
        {
            headerName: 'Vintage',
            field: 'Vintage',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'Vintage',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            hide: true,
            initialWidth: 100,
        },
        {
            headerName: 'Closing Date',
            field: 'ClosingDate',
            equals: (closingDate1, closingDate2) => {
                return closingDate1 !== closingDate2
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellEditor: 'datePicker',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    field: 'ClosingDate',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
            },
            cellStyle: getCellStyling,
            cellRenderer: (params) => {
                if (params.value) {
                    return moment(params.value).format('MM-DD-YYYY');
                }

                return null;
            },
            comparator: dateSort,
            filter: 'agDateColumnFilter',
            filterParams: () => {
                return {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                    comparator: dateFilter,
                };
            },
            initialWidth: 120,
        },
        {
            headerName: 'Asset Class',
            field: 'AssetClass',
            cellStyle: getCellStyling,
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            equals: (assetClass1, assetClass2) => {
                return assetClass1 !== assetClass2
            },
            cellEditorParams: () => {
                return {
                    // Remove hardcoded values and reintroduce once solution for dropdowns is found
                    // values: insertEmptyValue(assetClassLongNames),
                    values: ['', 'Private Equity', 'Private Debt', 'Real Estate', 'Real Assets'],
                    field: 'AssetClass',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            cellEditor: 'agSelectCellEditor',
            initialWidth: 130,
        },
        {
            headerName: 'Investment Type',
            field: 'InvestmentType',
            equals: (investmentType1, investmentType2) => {
                return investmentType1 !== investmentType2
            },
            cellStyle: getCellStyling,
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    values: [
                        '',
                        'Co-Investment',
                        'Primary',
                        'Secondary',
                        'Asset Management',
                    ],
                    field: 'InvestmentType',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            cellEditor: 'agSelectCellEditor',
            initialWidth: 140,
        },
        {
            headerName: 'Sector',
            field: 'Sector',
            cellStyle: getCellStyling,
            equals: (sector1, sector2) => {
                return sector1 !== sector2
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    values: insertEmptyValue(Sector),
                    field: 'Sector',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            cellEditor: 'agSelectCellEditor',
            initialWidth: 100,
        },
        {
            headerName: 'Sub-Sector',
            field: 'SubSector',
            cellStyle: getCellStyling,
            equals: (subSector1, subSector2) => {
                return subSector1 !== subSector2
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellEditorParams: () => {
                return {
                    values: insertEmptyValue(SubSector),
                    field: 'SubSector',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            cellEditor: 'agSelectCellEditor',
            initialWidth: 150,
        },
        {
            headerName: 'Geography',
            field: 'Geography',
            cellStyle: getCellStyling,
            equals: (geography1, geography2) => {
                return geography1 !== geography2
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            cellEditorParams: () => {
                return {
                    // values: insertEmptyValue(geographyNames),
                    values: insertEmptyValue(Geography),
                    field: 'Geography',
                    updateValue,
                };
            },
            onCellValueChanged: (event) => {
                const { newValue, column, data, oldValue } = event;
                updateValue(newValue, column.colId, data, oldValue);
                dropdownValidator(event);
            },
            cellEditor: 'agSelectCellEditor',
            initialWidth: 150,
        },
        {
            headerName: 'Commitment',
            field: 'Commitment',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                // TODO: Hacky solution to prevent resort after value changed.  Best solution is probably related to
                // when/where tableData is being set/called in TableDataGrid.js
                params.colDef.sortable = false;
                return {
                    data: params.data,
                };
            },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'Commitment',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: (event) => {
                const { newValue, column, data } = event;
                updateValue(newValue, column.colId, data);
                numericValidator(event);
            },
            initialWidth: 120,
        },
        {
            headerName: 'Contributions',
            field: 'Contributions',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            // cellRendererParams: (params) => {
            //     params.colDef.sortable = false;
            //     return {
            //         data: params.data,
            //     };
            // },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: (params) => {
                return {
                    params,
                    field: 'Contributions',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 120,
        },
        {
            headerName: 'Distributions',
            field: 'Distributions',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                params.colDef.sortable = false;
                return {
                    data: params.data,
                };
            },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'Distributions',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 120,
        },
        {
            headerName: 'AdjNAV',
            field: 'AdjNAV',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                params.colDef.sortable = false;
                return {
                    data: params.data,
                };
            },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'AdjNAV',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 100,
        },
        {
            headerName: 'Forward NAV',
            field: 'ForwardNAV',
            hide: !useForwardNav,
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: (params) => {
                params.colDef.sortable = false;
                if (useForwardNav) {
                    // if (useForwardNav || params?.data.hasOwnProperty('ForwardNAV')) {
                    return FormatNumber(params);
                }

                return 'N/A';
            },
            cellRendererParams: (params) => {
                return {
                    data: params.data,
                };
            },
            cellStyle: (params) => getCellStyling(params, useForwardNav),
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'ForwardNAV',
                    updateValue,
                };
            },
            editable: (params) =>
                useForwardNav && params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 100,
        },
        {
            headerName: 'Last NAV Date',
            field: 'LastNavDate',
            editable: false,
            cellStyle: getCellStyling,
            cellRenderer: (params) => {
                if (params.value) {
                    return moment(params.value).format('MM-DD-YYYY');
                }

                return null;
            },
            comparator: dateSort,
            filter: 'agDateColumnFilter',
            filterParams: () => {
                return {
                    inRangeInclusive: true,
                    suppressAndOrCondition: true,
                    comparator: dateFilter,
                };
            },
            initialWidth: 120,
        },
        {
            headerName: 'Unfunded',
            field: 'Unfunded',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                params.colDef.sortable = false;
                return {
                    data: params.data,
                };
            },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'Unfunded',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 100,
        },
        {
            headerName: 'IRR (%)',
            field: 'IRR',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellRenderer: FormatNumber,
            cellRendererParams: (params) => {
                params.colDef.sortable = false;
                return {
                    data: params.data,
                    decimal: 2,
                };
            },
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'IRR',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 85,
            cellRendererSelector: (params) => {
                if(params.column.colId === 'IRR'){
                    params.colDef.sortable = false;
                }

                if(params.data.Investment === 'Total'){
                    return TotalsRowRenderer
                } else {
                    return {
                        component: FormatNumber,
                        params: {
                            data: params.data,
                            decimal: 2,
                        }
                    }
                }
            },
        },
        {
            headerName: 'TVM (x)',
            field: 'TVM',
            filter: 'agNumberColumnFilter',
            type: 'numericColumn',
            cellStyle: getCellStyling,
            cellEditor: 'numericEditor',
            cellEditorParams: () => {
                return {
                    field: 'TVM',
                    updateValue,
                };
            },
            editable: (params) => params.node.rowPinned !== 'bottom',
            onCellValueChanged: numericValidator,
            initialWidth: 95,
            cellRendererSelector: (params) => {
                if(params.column.colId === 'TVM'){
                    params.colDef.sortable = false;
                }

                if(params.data.Investment === 'Total'){
                    return TotalsRowRenderer
                } else {
                    return {
                        component: FormatNumber,
                        params: {
                            data: params.data,
                            decimal: 2,
                        }
                    }
                }
            },
        },
    ];

    if (isSSGUser) {
        const idColumn = {
            headerName: 'Investment Id',
            field: 'investmentId',
            hide: true,
            editable: false,
            initialWidth: 70,
        }
        colDefs.splice(1, 0, idColumn)
    }

    attributes.forEach((attribute) => {
        colDefs.push({
            headerName: attribute.name,
            field: attribute.name,
            cellStyle: getCellStyling,
            hide: true,
            editable: false,
        });
    });

    // ensure Actions column is last
    colDefs.push({
        headerName: 'Actions',
        cellRenderer: TableDataActionButtons,
        cellRendererParams: () => {
            return {
                handleDelete: removeRow,
                handleReset: resetRow,
                value: 'default',
            };
        },
        editable: false,
        initialWidth: 92,
    });

    return colDefs;
}

