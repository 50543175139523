import { Grid, TableVariant } from "@ssgglobal/techintnue";
import React, { useContext, useRef } from "react";
import { ReadOnlyTableDataColumnDefinitions, TableDataColumnDefinitions, FrameworkComponents } from "./ColumnDefinitions";
import { AuthContext, LandingContext } from "context";
import useLandingGrid from "./hooks";

const LandingGrid = ({ isReadOnly }) => {
    const gridApi = useRef();
    const { useForwardNav, attributes, portfolioDropdown } = useContext(LandingContext);
    const { userSpiClientId } = useContext(AuthContext);

    const {
        data,
        totalData,
        updateValue,
        resetRow,
        removeRow,
        emptyUpdatedAfterSort,
    } = useLandingGrid({ api: gridApi?.current?.api });

    const handlePostSortRows = (params) => {
        const { nodes: rowNodes } = params;
        const sorted = rowNodes.filter((node) => node.data.lastUpdated).sort((a, b) => {
            return a.data.lastUpdated.index - b.data.lastUpdated.index
        });

        // here we put Ireland rows on top while preserving the sort order
        sorted.forEach((node) => {
            const { lastUpdated } = node.data;
            if (lastUpdated !== undefined) {
                // should move the node to rowIndex
                const rowIndex = lastUpdated.index;
                const oldIndex = rowNodes.findIndex((oldNode) => (
                    oldNode.data.Id === node.data.Id
                ));
                if (oldIndex || oldIndex === 0) {
                    // if found index
                    const foundNode = rowNodes.splice(oldIndex, 1)[0] // removes the current node from list
                    rowNodes.splice(
                        rowIndex, // index where to add
                        0, // dont remove nothing
                        foundNode, // it add the node again in the correct position
                    );
                }
            }
        })

    }

    const calculateSizes = () => {
        if (gridApi && gridApi.current && gridApi.current.api) {
            const { gridBodyCtrl: panel } = gridApi.current.api;
            if (panel && panel) {
                const {
                    eBodyViewport: {
                        clientWidth: availableWidth,
                    },
                    columnModel: {
                        displayedColumns,
                        getWidthOfColsInList,
                    }
                } = panel;
                const usedWidth = getWidthOfColsInList(displayedColumns);
                if (usedWidth < availableWidth) {
                    gridApi.current.api.sizeColumnsToFit();
                }
            }
        }
    }

    const columnDefs = isReadOnly
        ? ReadOnlyTableDataColumnDefinitions()
        : TableDataColumnDefinitions(
            updateValue,
            removeRow,
            resetRow,
            useForwardNav,
            attributes || [],
            portfolioDropdown,
            userSpiClientId
        );
    return (
        <Grid
            sx={{
                height: '500px',
                '& .ag-body-viewport': {
                    '& > *': {
                        minHeight: '100% !important'
                    },
                    border: 'var(--ag-borders) var(--ag-border-color)',
                }
            }}
        >
            <TableVariant
                gridStyle={{ height: '500px' }}
                ref={gridApi}
                tableType="aggrid"
                minHeight="500px"
                agTheme="ag-theme-balham"
                className="ag-grid-custom"
                agGridOptions={{
                    height: '500px',
                    defaultColDef: { resizable: true, filter: true, sortable: true, enablePivot: true, },
                    columnDefs: columnDefs,
                    rowData: [...data] || [],
                    pinnedBottomRowData: [...totalData] || [],
                    components: FrameworkComponents,
                    onSortChanged: (params) => {
                        if (params.source === "uiColumnSorted") {
                            emptyUpdatedAfterSort();
                        }
                    },
                    onGridReady: () => {
                        calculateSizes()
                    },
                    postSortRows: handlePostSortRows,
                    suppressAutoSize: true,
                    suppressScrollOnNewData: true,
                    enableRangeSelection: true,
                    enterMovesDown: true,
                    singleClickEdit: true,
                    stopEditingWhenCellsLoseFocus: true,
                    suppressExcelExport: true,
                    suppressCsvExport: true,
                    suppressMenuHide: true,
                }}
            />
        </Grid>
    );
};

export default LandingGrid;
